import { FormRow } from "@flixbus/honeycomb-react";
import { ReadOnlyFormField } from "@flixbus-phx/marketplace-common";
import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import CustomFormRow from "../../../../shared/components/customFormRow/CustomFormRow";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";

export type Props = {
  readOnly?: boolean;
};

const AddressLinesInput: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();

  const [, meta] = useField<Array<string>>("addressLines");
  const isMultiline = meta.value?.length > 1;

  if (readOnly) {
    const addressLines = meta.value.filter((value) => value !== "");

    if (addressLines.length) {
      return (
        <FormRow>
          <ReadOnlyFormField label={formatMessage({ id: "station.subHeadline" })}>
            {addressLines.map((value, index) => (
              <span key={value}>
                {value}
                {index < addressLines.length - 1 ? ", " : ""}
              </span>
            ))}
          </ReadOnlyFormField>
        </FormRow>
      );
    }

    return <></>;
  }

  return (
    <>
      <CustomFormRow>
        <FormikInput
          name="addressLines[0]"
          label={
            isMultiline
              ? `${formatMessage({ id: "station.addressLine" })} 1`
              : formatMessage({ id: "general.address" })
          }
          placeholder="340 W 50th St"
          type="text"
          readOnly={readOnly}
          readOnlyValueWhenEmpty={formatMessage({ id: "general.notAvailable" })}
          required
        />
      </CustomFormRow>
      {isMultiline && (
        <>
          <CustomFormRow>
            <FormikInput
              name="addressLines[1]"
              label={`${formatMessage({ id: "station.addressLine" })} 2`}
              placeholder="NY 10019"
              type="text"
              readOnly={readOnly}
              readOnlyValueWhenEmpty={formatMessage({ id: "general.notAvailable" })}
              required
            />
          </CustomFormRow>
          <CustomFormRow>
            <FormikInput
              name="addressLines[2]"
              label={`${formatMessage({ id: "station.addressLine" })} 3`}
              placeholder="New York City"
              type="text"
              readOnly={readOnly}
              readOnlyValueWhenEmpty={formatMessage({ id: "general.notAvailable" })}
              required
            />
          </CustomFormRow>
        </>
      )}
    </>
  );
};

export default AddressLinesInput;
