import { NormalizedInterval } from "date-fns";
import { OriginalSchedulesOfLineQuery } from "../api/operations.generated";

export enum CalendarIntervalType {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
}

export type InputSchedule = OriginalSchedulesOfLineQuery["findOriginalSchedules"][number];

export type CalendarSchedule = Omit<InputSchedule, "conceptChanges">;

export type ScheduleChunk = {
  uiPeriod: NormalizedInterval;
  isFirstOccurrence: boolean;
  schedules: Array<CalendarSchedule>;
};

export type InvisibleConceptChange = {
  uiPeriod: NormalizedInterval;
  schedule: CalendarSchedule;
};

export type CalendarRow = {
  chunks: Array<ScheduleChunk>;
  invisibleConceptChanges: Array<InvisibleConceptChange>;
  hasInvisibleConceptChanges: boolean;
};

export type Calendar = {
  intervalType: CalendarIntervalType;
  rows: Array<CalendarRow>;
};

export enum AggregatedScheduleStatus {
  ON_SALE = "ON_SALE",
  PROCESSING = "PROCESSING",
  HIDDEN = "HIDDEN",
  IN_REVIEW = "IN_REVIEW",
  DRAFT = "DRAFT",
  INACTIVE = "INACTIVE",
}
