@use "variables" as *;
@use "../../helper/styles/styles";

.expandButtonRow {
  position: absolute;
  left: 0;
  width: 100%;
  border-bottom: $border;

  .col {
    border-right: styles.$border-light;
    background-color: $bg-secondary-color;
    height: 100%;
    position: absolute;
  }

  .button {
    position: fixed;
    transform: translateX(5px);
    z-index: 1;
  }
}
