import { SelectOption } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import Select from "../../../../shared/components/select/Select";
import defaultOption from "../../helper/defaultOption/defaultOption";

type Props = {
  label: string;
  options: Array<SelectOption>;
  selected: SelectOption["value"] | null;
  onChange: (value: SelectOption["value"] | null) => void;
};

const FilterSelect: React.FC<Props> = ({ label, options, selected, onChange }) => {
  const initialOption = options.find((option) => selected === option.value);

  return (
    <Select
      label={label}
      initialValue={initialOption || defaultOption()}
      options={options}
      onSelect={(selection) => {
        if (selection.value === "") {
          onChange(null);
        } else {
          onChange(selection.value);
        }
      }}
      onClear={initialOption !== undefined ? () => onChange(null) : undefined}
    />
  );
};

export default FilterSelect;
