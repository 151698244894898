import {
  Icon,
  IconBus,
  IconFerry,
  IconMinibus,
  IconTrain,
} from "@flixbus/honeycomb-icons-react";
import { formatMessage, SelectOption } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { MeansOfTransport } from "../../types/schema";

const meansOfTransPortOptions = (): Array<SelectOption> => [
  {
    value: MeansOfTransport.Bus,
    label: formatMessage(`meansOfTransport.${MeansOfTransport.Bus}`),
    icon: <Icon InlineIcon={IconBus} />,
  },
  {
    value: MeansOfTransport.Train,
    label: formatMessage(`meansOfTransport.${MeansOfTransport.Train}`),
    icon: <Icon InlineIcon={IconTrain} />,
  },
  {
    value: MeansOfTransport.Ferry,
    label: formatMessage(`meansOfTransport.${MeansOfTransport.Ferry}`),
    icon: <Icon InlineIcon={IconFerry} />,
  },
  {
    value: MeansOfTransport.MiniBus,
    label: formatMessage(`meansOfTransport.${MeansOfTransport.MiniBus}`),
    icon: <Icon InlineIcon={IconMinibus} />,
  },
];

export default meansOfTransPortOptions;
