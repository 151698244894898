import {
  IconBike,
  IconSeatSolid,
  IconWheelchairSolid,
} from "@flixbus/honeycomb-icons-react";
import { formatMessage, SelectOption } from "@flixbus-phx/marketplace-common";
import { Circulation } from "../../../types/schema";

const getLabel = (name: Circulation["name"]) => {
  const splitted = name.split(" - ");

  return formatMessage("partnerDetail.defaultCapacity.id.optionLabel", {
    circulationId:
      splitted.length === 2 ? splitted[1] : formatMessage("general.notAvailable"),
  });
};

export default (circulation: Circulation): SelectOption => {
  return {
    value: circulation.uuid,
    label: getLabel(circulation.name),
    multiIconLabel: [
      {
        icon: IconSeatSolid,
        label: circulation.activeConfiguration!!.seatCapacity,
      },
      { icon: IconBike, label: circulation.activeConfiguration!!.bikeCapacity },
      {
        icon: IconWheelchairSolid,
        label: circulation.activeConfiguration!!.wheelchairCapacity,
      },
    ],
  };
};
