import { differenceInCalendarDays, endOfMonth, startOfMonth } from "date-fns";
import {
  contentCellWidthDaily,
  contentCellWidthMonthly,
  fullContainerWidth,
} from "../sizes/sizes";
import { CalendarIntervalType } from "../types";

const calculateStickyHeaderWidth = (
  index: number,
  interval: Date[],
  intervalType: CalendarIntervalType
) => {
  if (index === interval.length - 1) {
    return fullContainerWidth;
  }
  const date = interval[index];

  const cols = differenceInCalendarDays(endOfMonth(date), startOfMonth(date)) + 1;

  return intervalType === CalendarIntervalType.DAILY
    ? cols * contentCellWidthDaily
    : 12 * contentCellWidthMonthly;
};

export default calculateStickyHeaderWidth;
