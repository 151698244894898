@use "variables" as *;

.box {
  padding: $spacing-2;
}

.checkBox {
  font-weight: $font-weight-bold;
}

.checked {
  margin-bottom: $spacing-2;
}

.unchecked {
  margin-bottom: 0;
}

.minTransferTime {
  margin-top: $spacing-2;
}

.transferTime {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: $spacing-2;
}

.transferTimeSelect {
  flex-shrink: 0;
}

.tooltipContent {
  margin: 0;
  padding-left: 20px;
}
