// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type StationFragment = {
  __typename?: "Station";
  id: string;
  name: string;
  shortName?: string | null;
  code?: string | null;
  addressLines: Array<string>;
  addressCity?: string | null;
  zipCode?: string | null;
  countryCode?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  isPublished: boolean;
  status: Types.StationStatus;
  rejectionReason?: string | null;
  hasNearbyStation?: boolean | null;
  timeZone: {
    __typename?: "StationTimeZone";
    zoneId: string;
    observesDaylightSavingTime: boolean;
  };
  city?: { __typename?: "City"; value: string; label: string } | null;
  transferInformation?: {
    __typename?: "StationTransferInformation";
    isTransferStation: boolean;
    minimumTransferMinutes?: number | null;
    transferOpeningHours: Array<{
      __typename?: "StationTransferInformationOpeningHours";
      weekday: Types.DayOfWeek;
      start: string;
      end: string;
    }>;
  } | null;
  alternativeStation?: { __typename?: "Station"; id: string; name: string } | null;
};

export type FindStationQueryVariables = Types.Exact<{
  stationId: Types.Scalars["ID"]["input"];
}>;

export type FindStationQuery = {
  __typename?: "Query";
  findStation: {
    __typename?: "Station";
    id: string;
    name: string;
    shortName?: string | null;
    code?: string | null;
    addressLines: Array<string>;
    addressCity?: string | null;
    zipCode?: string | null;
    countryCode?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    isPublished: boolean;
    status: Types.StationStatus;
    rejectionReason?: string | null;
    hasNearbyStation?: boolean | null;
    timeZone: {
      __typename?: "StationTimeZone";
      zoneId: string;
      observesDaylightSavingTime: boolean;
    };
    city?: { __typename?: "City"; value: string; label: string } | null;
    transferInformation?: {
      __typename?: "StationTransferInformation";
      isTransferStation: boolean;
      minimumTransferMinutes?: number | null;
      transferOpeningHours: Array<{
        __typename?: "StationTransferInformationOpeningHours";
        weekday: Types.DayOfWeek;
        start: string;
        end: string;
      }>;
    } | null;
    alternativeStation?: { __typename?: "Station"; id: string; name: string } | null;
  };
};

export type UpdateStationMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  name: Types.Scalars["String"]["input"];
  timezone: Types.Scalars["String"]["input"];
  code?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  shortName?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  latitude: Types.Scalars["String"]["input"];
  longitude: Types.Scalars["String"]["input"];
  countryCode: Types.IsoCountry;
  zipCode: Types.Scalars["String"]["input"];
  addressCity: Types.Scalars["String"]["input"];
  addressLines:
    | Array<Types.Scalars["String"]["input"]>
    | Types.Scalars["String"]["input"];
  cityId: Types.Scalars["ID"]["input"];
  transferInformation: Types.StationTransferInformationInput;
}>;

export type UpdateStationMutation = {
  __typename?: "Mutation";
  updateStation: {
    __typename?: "Station";
    id: string;
    name: string;
    shortName?: string | null;
    code?: string | null;
    addressLines: Array<string>;
    addressCity?: string | null;
    zipCode?: string | null;
    countryCode?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    isPublished: boolean;
    status: Types.StationStatus;
    rejectionReason?: string | null;
    hasNearbyStation?: boolean | null;
    timeZone: {
      __typename?: "StationTimeZone";
      zoneId: string;
      observesDaylightSavingTime: boolean;
    };
    city?: { __typename?: "City"; value: string; label: string } | null;
    transferInformation?: {
      __typename?: "StationTransferInformation";
      isTransferStation: boolean;
      minimumTransferMinutes?: number | null;
      transferOpeningHours: Array<{
        __typename?: "StationTransferInformationOpeningHours";
        weekday: Types.DayOfWeek;
        start: string;
        end: string;
      }>;
    } | null;
    alternativeStation?: { __typename?: "Station"; id: string; name: string } | null;
  };
};

export type FindStationsInScheduleAndCityQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
  stationId: Types.Scalars["ID"]["input"];
}>;

export type FindStationsInScheduleAndCityQuery = {
  __typename?: "Query";
  findStation: {
    __typename?: "Station";
    id: string;
    name: string;
    city?: {
      __typename?: "City";
      id: string;
      stations: Array<{ __typename?: "Station"; id: string; name: string }>;
    } | null;
  };
  findSchedule: {
    __typename?: "Schedule";
    id: string;
    readOnly: boolean;
    stations: Array<{ __typename?: "Station"; id: string }>;
  };
};

export const StationFragmentDoc = gql`
  fragment Station on Station {
    id
    name
    shortName
    code
    timeZone {
      zoneId
      observesDaylightSavingTime
    }
    addressLines
    addressCity
    zipCode
    countryCode
    latitude
    longitude
    isPublished
    city {
      value: id
      label: name
    }
    transferInformation {
      isTransferStation
      minimumTransferMinutes
      transferOpeningHours {
        weekday
        start
        end
      }
    }
    status
    rejectionReason
    alternativeStation {
      id
      name
    }
    hasNearbyStation
  }
`;
export const FindStationDocument = gql`
  query findStation($stationId: ID!) {
    findStation(id: $stationId) {
      ...Station
    }
  }
  ${StationFragmentDoc}
`;

/**
 * __useFindStationQuery__
 *
 * To run a query within a React component, call `useFindStationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStationQuery({
 *   variables: {
 *      stationId: // value for 'stationId'
 *   },
 * });
 */
export function useFindStationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindStationQuery,
    FindStationQueryVariables
  > &
    ({ variables: FindStationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindStationQuery, FindStationQueryVariables>(
    FindStationDocument,
    options
  );
}
export function useFindStationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindStationQuery,
    FindStationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindStationQuery, FindStationQueryVariables>(
    FindStationDocument,
    options
  );
}
export function useFindStationSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindStationQuery,
        FindStationQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<FindStationQuery, FindStationQueryVariables>(
    FindStationDocument,
    options
  );
}
export type FindStationQueryHookResult = ReturnType<typeof useFindStationQuery>;
export type FindStationLazyQueryHookResult = ReturnType<typeof useFindStationLazyQuery>;
export type FindStationSuspenseQueryHookResult = ReturnType<
  typeof useFindStationSuspenseQuery
>;
export const UpdateStationDocument = gql`
  mutation updateStation(
    $id: ID!
    $name: String!
    $timezone: String!
    $code: String
    $shortName: String
    $latitude: String!
    $longitude: String!
    $countryCode: ISOCountry!
    $zipCode: String!
    $addressCity: String!
    $addressLines: [String!]!
    $cityId: ID!
    $transferInformation: StationTransferInformationInput!
  ) {
    updateStation(
      id: $id
      name: $name
      timezone: $timezone
      code: $code
      shortName: $shortName
      latitude: $latitude
      longitude: $longitude
      countryCode: $countryCode
      zipCode: $zipCode
      addressCity: $addressCity
      addressLines: $addressLines
      cityId: $cityId
      transferInformation: $transferInformation
    ) {
      ...Station
    }
  }
  ${StationFragmentDoc}
`;

/**
 * __useUpdateStationMutation__
 *
 * To run a mutation, you first call `useUpdateStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStationMutation, { data, loading, error }] = useUpdateStationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      timezone: // value for 'timezone'
 *      code: // value for 'code'
 *      shortName: // value for 'shortName'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      countryCode: // value for 'countryCode'
 *      zipCode: // value for 'zipCode'
 *      addressCity: // value for 'addressCity'
 *      addressLines: // value for 'addressLines'
 *      cityId: // value for 'cityId'
 *      transferInformation: // value for 'transferInformation'
 *   },
 * });
 */
export function useUpdateStationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateStationMutation,
    UpdateStationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateStationMutation,
    UpdateStationMutationVariables
  >(UpdateStationDocument, options);
}
export type UpdateStationMutationHookResult = ReturnType<typeof useUpdateStationMutation>;
export const FindStationsInScheduleAndCityDocument = gql`
  query findStationsInScheduleAndCity($scheduleId: ID!, $stationId: ID!) {
    findStation(id: $stationId) {
      id
      name
      city {
        id
        stations {
          id
          name
        }
      }
    }
    findSchedule(id: $scheduleId) {
      id
      readOnly
      stations {
        id
      }
    }
  }
`;

/**
 * __useFindStationsInScheduleAndCityQuery__
 *
 * To run a query within a React component, call `useFindStationsInScheduleAndCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStationsInScheduleAndCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStationsInScheduleAndCityQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      stationId: // value for 'stationId'
 *   },
 * });
 */
export function useFindStationsInScheduleAndCityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindStationsInScheduleAndCityQuery,
    FindStationsInScheduleAndCityQueryVariables
  > &
    (
      | { variables: FindStationsInScheduleAndCityQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindStationsInScheduleAndCityQuery,
    FindStationsInScheduleAndCityQueryVariables
  >(FindStationsInScheduleAndCityDocument, options);
}
export function useFindStationsInScheduleAndCityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindStationsInScheduleAndCityQuery,
    FindStationsInScheduleAndCityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindStationsInScheduleAndCityQuery,
    FindStationsInScheduleAndCityQueryVariables
  >(FindStationsInScheduleAndCityDocument, options);
}
export function useFindStationsInScheduleAndCitySuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindStationsInScheduleAndCityQuery,
        FindStationsInScheduleAndCityQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindStationsInScheduleAndCityQuery,
    FindStationsInScheduleAndCityQueryVariables
  >(FindStationsInScheduleAndCityDocument, options);
}
export type FindStationsInScheduleAndCityQueryHookResult = ReturnType<
  typeof useFindStationsInScheduleAndCityQuery
>;
export type FindStationsInScheduleAndCityLazyQueryHookResult = ReturnType<
  typeof useFindStationsInScheduleAndCityLazyQuery
>;
export type FindStationsInScheduleAndCitySuspenseQueryHookResult = ReturnType<
  typeof useFindStationsInScheduleAndCitySuspenseQuery
>;
