import { ReadOnlyFormField, SelectOption } from "@flixbus-phx/marketplace-common";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import MultiSelect from "../multiSelect/MultiSelect";
import getSelectedOptionsByValue from "./helper/getSelectedOptionsByValue/getSelectedOptionsByValue";

export type FormikMultiSelectProps = {
  name: string;
  label: string;
  maxToShow: number;
  placeholder?: string;
  required?: boolean;
  options: Array<SelectOption>;
  readOnly?: boolean;
};

const FormikMultiSelect: React.FC<FormikMultiSelectProps> = ({
  name,
  label,
  maxToShow,
  placeholder,
  required,
  options,
  readOnly = false,
}) => {
  const [, meta, helper] = useField<Array<SelectOption["value"]>>(name);

  const initialSelectedOptions = getSelectedOptionsByValue(options, meta.value);

  if (readOnly) {
    return (
      <ReadOnlyFormField label={label}>
        {initialSelectedOptions ? (
          initialSelectedOptions.map((option) => option.label).join(", ")
        ) : (
          <FormattedMessage id="general.notAvailable" />
        )}
      </ReadOnlyFormField>
    );
  }

  return (
    <MultiSelect
      label={`${label}${required ? " *" : ""}`}
      placeholder={placeholder}
      initialSelectedOptions={initialSelectedOptions}
      options={options}
      onSelect={(opts) => {
        helper.setValue(opts.map((option) => option.value));
      }}
      onBlur={() => helper.setTouched(true)}
      errorText={meta.error && meta.touched ? meta.error : undefined}
      maxToShow={maxToShow}
    />
  );
};

export default FormikMultiSelect;
