import { Fineprint } from "@flixbus/honeycomb-react";
import {
  autocompleteStyles,
  ClearIndicator,
  DropdownIndicator,
  multiSelectStyles,
  removeSpacesAndSpecialCharacters,
  SelectOption,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import ReactSelect, {
  OptionProps as ReactSelectOptionProps,
  MultiValueProps as ReactSelectMultiValueProps,
} from "react-select";
import * as css from "./MultiSelect.scss";
import MultiSelectOption from "./ui/multiSelectOption/MultiSelectOption";
import MultiValue from "./ui/multiValue/MultiValue";
import MultiValueRemove from "./ui/multiValueRemove/MultiValueRemove";

export type MultiSelectProps = {
  options: Array<SelectOption>;
  onSelect: (selectedValue: Array<SelectOption>) => void;
  maxToShow: number;
  label?: string;
  placeholder?: string;
  initialSelectedOptions?: Array<SelectOption>;
  onBlur?: () => void;
  errorText?: string;
  isDisabled?: boolean;
  deleteSelection?: (func: Function) => void;
};

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  onSelect,
  maxToShow,
  label,
  placeholder,
  initialSelectedOptions = [],
  onBlur,
  errorText,
  isDisabled,
  deleteSelection,
}) => {
  const [selectedOptions, setSelectedOptions] = React.useState(initialSelectedOptions);

  if (deleteSelection) {
    deleteSelection(setSelectedOptions);
  }

  const renderMultiValue = (props: ReactSelectMultiValueProps<SelectOption>) => (
    <MultiValue {...props} maxToShow={maxToShow} />
  );

  const renderOption = (props: ReactSelectOptionProps<SelectOption, false>) => (
    <MultiSelectOption {...props} />
  );

  return (
    <>
      {label && (
        <div className={css.labelWrapper}>
          <label className={css.label} htmlFor={`select-${label}`}>
            {label}
          </label>
        </div>
      )}
      <ReactSelect
        isMulti
        id={`select-${removeSpacesAndSpecialCharacters(label) || Math.random()}`}
        data-id="multi-select"
        placeholder={placeholder}
        components={{
          ClearIndicator,
          DropdownIndicator,
          MultiValue: renderMultiValue,
          MultiValueRemove,
          Option: renderOption as React.ComponentType<
            ReactSelectOptionProps<SelectOption, boolean>
          >,
        }}
        styles={{
          ...autocompleteStyles(!!errorText, true, undefined, undefined, !!label),
          ...multiSelectStyles(),
        }}
        value={selectedOptions}
        options={options}
        onChange={(opts) => {
          const newSelectedOptions = (opts as Array<SelectOption>) || [];
          setSelectedOptions(newSelectedOptions);
          onSelect(newSelectedOptions);
        }}
        onBlur={onBlur}
        isDisabled={isDisabled}
        hideSelectedOptions={false}
      />
      {errorText && <Fineprint extraClasses={css.errorText}>{errorText}</Fineprint>}
    </>
  );
};

export default MultiSelect;
