import { SelectOption } from "@flixbus-phx/marketplace-common";
import { Schedule } from "../../../../shared/types/schema";
import { FindAllSchedulesForImportQuery } from "../../api/operations.generated";

export default (
  schedules: FindAllSchedulesForImportQuery["findSchedule"]["line"]["schedules"],
  sourceScheduleId: Schedule["id"]
): Array<SelectOption> => {
  const schedulesForImport: Array<SelectOption> = [];

  schedules.forEach((schedule) => {
    if (schedule.canBeUsedForPriceImport && schedule.id !== sourceScheduleId) {
      schedulesForImport.push({ value: schedule.id, label: schedule.name });
    }
  });

  return schedulesForImport;
};
