import { compareAsc, interval } from "date-fns";
import { getInvisibleConceptChanges } from "../inactiveConceptChanges/invisibleConceptChanges";
import isOrWasScheduleOnSale from "../isOrWasScheduleOnSale/isOrWasScheduleOnSale";
import {
  Calendar,
  CalendarIntervalType,
  CalendarSchedule,
  InputSchedule,
  ScheduleChunk,
} from "../types";

const buildDailyCalendar = (
  inputSchedules: Array<InputSchedule>,
  showInactive: boolean = false
): Calendar => {
  const rows = inputSchedules
    .map((inputSchedule) => {
      const chunks: Array<ScheduleChunk> = [];
      const processedSchedules = new Set<CalendarSchedule>();

      if (isOrWasScheduleOnSale(inputSchedule)) {
        [
          inputSchedule,
          ...inputSchedule.conceptChanges.filter((cc) => isOrWasScheduleOnSale(cc)),
        ].forEach((schedule) => {
          schedule.onSalePeriods.forEach((period) => {
            const isFirstOccurrence = !processedSchedules.has(schedule);
            if (isFirstOccurrence) processedSchedules.add(schedule);

            const normalizedInterval = interval(period.start, period.end);

            chunks.push({
              uiPeriod: normalizedInterval,
              isFirstOccurrence,
              schedules: [schedule],
            });
          });
        });
      } else {
        const isFirstOccurrence = !processedSchedules.has(inputSchedule);
        if (isFirstOccurrence) processedSchedules.add(inputSchedule);

        const normalizedInterval = interval(
          inputSchedule.period.start,
          inputSchedule.period.end
        );

        chunks.push({
          uiPeriod: normalizedInterval,
          isFirstOccurrence,
          schedules: [inputSchedule],
        });
      }

      chunks.sort((a, b) => compareAsc(a.uiPeriod.start, b.uiPeriod.start));

      const invisibleConceptChanges = getInvisibleConceptChanges(
        inputSchedule,
        inputSchedule.conceptChanges,
        showInactive
      );

      return {
        chunks,
        invisibleConceptChanges,
        hasInvisibleConceptChanges: invisibleConceptChanges.length > 0,
      };
    })
    .filter((row) => row.chunks.length > 0);

  return {
    intervalType: CalendarIntervalType.DAILY,
    rows,
  };
};

export default buildDailyCalendar;
