import {
  endOfDay,
  endOfMonth,
  interval,
  isWithinInterval,
  NormalizedInterval,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { CalendarIntervalType } from "../types";

const isDateWithinPeriod = (
  intervalType: CalendarIntervalType,
  date: Date,
  period: NormalizedInterval
): boolean => {
  const typedPeriod =
    intervalType === CalendarIntervalType.DAILY
      ? interval(startOfDay(period.start), endOfDay(period.end))
      : interval(startOfMonth(period.start), endOfMonth(period.end));

  return isWithinInterval(date, typedPeriod);
};

export default isDateWithinPeriod;
