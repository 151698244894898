import { VirtualItem } from "@tanstack/react-virtual";
import { NormalizedInterval } from "date-fns";
import React from "react";
import isDateWithinPeriod from "../../helper/isDateWithinPeriod/isDateWithinPeriod";
import {
  CalendarIntervalType,
  CalendarSchedule,
  InvisibleConceptChange,
} from "../../helper/types";
import CalendarContentCell from "../calendarContentCell/CalendarContentCell";

export type InvisibleConceptChangeRowProps = {
  conceptChange: InvisibleConceptChange;
  rowStart: number;
  columns: VirtualItem[];
  displayDates: Date[];
  intervalType: CalendarIntervalType;
};

const InvisibleConceptChangeRow: React.FC<InvisibleConceptChangeRowProps> = ({
  conceptChange,
  rowStart,
  columns,
  displayDates,
  intervalType,
}) => {
  let firstOccurence: boolean | undefined;

  return (
    <div
      data-testing="cc-row"
      style={{
        transform: `translateY(${rowStart}px)`,
      }}
    >
      {columns.map((virtualColItem) => {
        const currentDate = displayDates[virtualColItem.index];
        let schedules: CalendarSchedule[] = [];
        let period: NormalizedInterval | undefined;
        if (isDateWithinPeriod(intervalType, currentDate, conceptChange.uiPeriod)) {
          if (firstOccurence === undefined) firstOccurence = true;
          schedules = [conceptChange.schedule];
          period = conceptChange.uiPeriod;
        }

        const cell = (
          <CalendarContentCell
            key={`${conceptChange.schedule.id}-${virtualColItem.key}`}
            intervalType={intervalType}
            currentDate={currentDate}
            virtualColItem={virtualColItem}
            schedules={schedules}
            isFirstOccurrence={firstOccurence || false}
            period={period}
            hasInvisibleConceptChanges
          />
        );

        if (firstOccurence && schedules.length > 0) firstOccurence = false;

        return cell;
      })}
    </div>
  );
};

export default InvisibleConceptChangeRow;
