import cx from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import buildPath from "../../../../routes/buildPath/buildPath";
import { CalendarSchedule } from "../../helper/types";
import ScheduleCellTooltip from "../scheduleCellTooltip/ScheduleCellTooltip";
import * as css from "./ScheduleCell.scss";
import getCssClass from "./scheduleCellHelper";

export type ScheduleCellProps = {
  colStart: number;
  highlight: boolean;
  setHighlight: (highlight: boolean) => void;
  schedules: Array<CalendarSchedule>;
  isFirstOccurrence: boolean;
  cellWidth: number;
  isWeekend: boolean;
  isStart: boolean;
  isEnd: boolean;
  textBoxWidth: number;
  hasInvisibleConceptChanges: boolean;
};

const ScheduleCell: React.FC<ScheduleCellProps> = ({
  colStart,
  highlight,
  setHighlight,
  schedules,
  isFirstOccurrence,
  cellWidth,
  isWeekend,
  isStart,
  isEnd,
  textBoxWidth,
  hasInvisibleConceptChanges,
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleMouseEnter = () => {
    setHighlight(true);
  };

  const handleMouseLeave = () => {
    setHighlight(false);
  };

  const hasTooltip = schedules.length > 1;

  let zIndex = 0;
  if (isStart) zIndex++;
  if (showTooltip) zIndex++;

  return (
    <div
      className={cx(
        css.scrollableColumn,
        isWeekend && css.weekend,
        hasInvisibleConceptChanges && css.hasInvisibleConceptChanges
      )}
      style={{
        width: `${cellWidth}px`,
        transform: `translateX(${colStart}px)`,
        zIndex,
      }}
    >
      {schedules.length ? (
        <>
          <Link
            className={css.scheduleLink}
            to={buildPath.showScheduleDetail(schedules[0].id)}
          >
            <div
              className={cx(
                css.scheduleCell,
                getCssClass(schedules[0]),
                isStart && css.start,
                isEnd && css.end,
                isFirstOccurrence && css.isFirst,
                highlight && css.highlight
              )}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
            {isStart && isFirstOccurrence && (
              <div
                className={cx(css.scheduleName, hasTooltip && css.hasTooltip)}
                style={{
                  width: `${textBoxWidth}px`,
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {schedules[0].name}
              </div>
            )}
          </Link>
          {hasTooltip && (
            <ScheduleCellTooltip schedules={schedules} onToggle={setShowTooltip} />
          )}
        </>
      ) : null}
    </div>
  );
};

export default ScheduleCell;
