import { VirtualItem } from "@tanstack/react-virtual";
import {
  isSameDay,
  differenceInCalendarDays,
  isWeekend,
  isSameMonth,
  differenceInCalendarMonths,
  NormalizedInterval,
} from "date-fns";
import * as React from "react";
import { Schedule } from "../../../../shared/types/schema";
import { contentCellWidthDaily, contentCellWidthMonthly } from "../../helper/sizes/sizes";
import { CalendarIntervalType, CalendarSchedule } from "../../helper/types";
import ScheduleCell from "../scheduleCell/ScheduleCell";

export type CalendarContentCellProps = {
  intervalType: CalendarIntervalType;
  currentDate: Date;
  period?: NormalizedInterval;
  schedules: CalendarSchedule[];
  virtualColItem: VirtualItem;
  hoveredSchedule?: Schedule["id"];
  setHoveredSchedule?: (schduleId?: Schedule["id"]) => void;
  isFirstOccurrence: boolean;
  hasInvisibleConceptChanges: boolean;
};

const CalendarContentCell: React.FC<CalendarContentCellProps> = ({
  intervalType,
  currentDate,
  period,
  schedules,
  virtualColItem,
  hoveredSchedule,
  setHoveredSchedule,
  isFirstOccurrence,
  hasInvisibleConceptChanges,
}) => {
  const isDaily = intervalType === CalendarIntervalType.DAILY;

  let isStart = false;
  let isEnd = false;
  let textBoxWidth = 0;

  if (period) {
    if (isDaily) {
      isStart = isSameDay(currentDate, period.start);
      isEnd = isSameDay(currentDate, period.end);
      textBoxWidth =
        46 +
        contentCellWidthDaily *
          differenceInCalendarDays(new Date(period.end), new Date(period.start));
    } else {
      isStart = isSameMonth(currentDate, period.start);
      isEnd = isSameMonth(currentDate, period.end);
      textBoxWidth =
        78 +
        contentCellWidthMonthly *
          differenceInCalendarMonths(new Date(period.end), new Date(period.start));
    }
  }

  return (
    <ScheduleCell
      colStart={virtualColItem.start}
      highlight={schedules.some((schedule) => schedule.id === hoveredSchedule)}
      setHighlight={(highlight) => {
        if (setHoveredSchedule !== undefined) {
          setHoveredSchedule(highlight ? schedules[0].id : undefined);
        }
      }}
      schedules={schedules}
      isFirstOccurrence={isFirstOccurrence}
      cellWidth={virtualColItem.size}
      isWeekend={isDaily && isWeekend(currentDate)}
      isStart={isStart}
      isEnd={isEnd}
      textBoxWidth={textBoxWidth}
      hasInvisibleConceptChanges={hasInvisibleConceptChanges}
    />
  );
};

export default CalendarContentCell;
