import { Input } from "@flixbus/honeycomb-react";
import { ReadOnlyFormField } from "@flixbus-phx/marketplace-common";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import getUnitText from "../../helpers/getUnitText/getUnitText";
import { formatPriceInput } from "../../helpers/priceInputFormatter/priceInputFormatter";
import isValid from "../../helpers/validateFormikInput/validateFormikInput";
import * as css from "./FormikUnitInput.scss";

enum Unit {
  weight = "WEIGHT",
  length = "LENGTH",
  currency = "CURRENCY",
}

type FormikUnitInputProps = {
  name: string;
  label: string;
  readOnly?: boolean;
  info?: string;
  maxLength?: number;
  required?: boolean;
  isPriceInput?: boolean;
};

const FormikUnitInput: React.FC<FormikUnitInputProps> = ({
  name,
  label,
  readOnly,
  info,
  maxLength,
  required,
  isPriceInput = false,
}) => {
  const [field, meta, helper] = useField(`${name}.value`);
  const [globalUnit] = useField("units");

  let unit: Unit;
  let unitText;

  if (name.includes("maxWeight")) {
    unit = Unit.weight;
    unitText = getUnitText(globalUnit.value.weightUnit);
  } else if (name.includes("maxDimension")) {
    unit = Unit.length;
    unitText = getUnitText(globalUnit.value.lengthUnit);
  } else {
    unit = Unit.currency;
    unitText = globalUnit.value.currency;
  }

  const isValidInput = isValid(meta);

  if (readOnly) {
    return (
      <ReadOnlyFormField label={label}>
        {meta.value ? (
          `${meta.value} ${unitText}`
        ) : (
          <FormattedMessage id="general.notAvailable" />
        )}
      </ReadOnlyFormField>
    );
  }

  const LABEL = label ? `${label}${required ? " *" : ""}` : label;

  return (
    <div>
      <div data-id="unitInput-label" className={css.unitInputLabel}>
        {LABEL}
      </div>
      <div>
        <Input
          id={`${name}-input`}
          aria-label={LABEL}
          extraClasses={css.input}
          valid={isValidInput}
          maxLength={maxLength}
          {...field}
          inlineLabelLeft={unit === Unit.currency ? unitText : undefined}
          inlineLabelRight={unit !== Unit.currency ? unitText : undefined}
          onBlur={() => {
            if (isPriceInput) {
              const formattedValue = formatPriceInput(meta.value);
              helper.setValue(formattedValue);
            }
            helper.setTouched(true);
          }}
          onChange={(event) => {
            if (unit === Unit.weight && event.target.value !== "") {
              helper.setValue(Number.parseInt(event.target.value, 10));
            } else {
              helper.setValue(event.target.value);
            }
          }}
        />
      </div>

      {isValidInput === false && meta.error && (
        <>
          <div data-id="error-text" className={`${css.errorText} ${css.finePrint}`}>
            {meta.error}
          </div>
        </>
      )}
      {info && (
        <div data-id="info-text" className={css.finePrint}>
          {info}
        </div>
      )}
    </div>
  );
};

export default FormikUnitInput;
