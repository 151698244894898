import { Icon, IconDelete } from "@flixbus/honeycomb-icons-react";
import { ListWrapper, ListWrapperItem } from "@flixbus/honeycomb-react";
import {
  Autocomplete,
  DefaultInfoAlert,
  debounce,
  legacyTranslate,
  SelectOption,
} from "@flixbus-phx/marketplace-common";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import InterconnectionOperatorIcon from "../../../../shared/components/interconnectionOperatorIcon/InterconnectionOperatorIcon";
import { Operator } from "../../../../shared/types/schema";
import { useSearchOperatorsAsOptionsLazyQuery } from "../../api/operations.generated";
import getSelectableOperatorOptions from "../../helpers/getSelectableOperatorOptions/getSelectableOperatorOptions";
import * as css from "./OperatorSelect.scss";

const MIN_SEARCH_LENGTH = 3;
const TRIGGER_DEBOUNCE_MS = 200;

const debounced = debounce((f: Function) => f(), TRIGGER_DEBOUNCE_MS);

export type OperatorSelectProps = {
  isWhitelist: boolean;
};

const OperatorSelect: React.FC<OperatorSelectProps> = ({ isWhitelist }) => {
  const { formatMessage } = useIntl();
  const [, meta, helper] = useField<Array<Operator>>(
    "inventoryRule.interconnectionRule.operators"
  );

  const operatorsSelected = meta.value.sort((a, b) => a.name.localeCompare(b.name));

  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<Array<SelectOption>>([]);
  const [searchLoading, setSearchLoading] = React.useState(false);

  const [triggerSearch, { error }] = useSearchOperatorsAsOptionsLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data) {
        const selectableOperatorOptions = getSelectableOperatorOptions(
          data.searchOperators,
          operatorsSelected
        );

        setOptions(selectableOperatorOptions);
        setSearchLoading(false);
      }
    },
  });

  React.useEffect(() => {
    if (inputValue.length === 0) {
      setOptions([]);
    }
    const input = inputValue.trim();
    if (input.length >= MIN_SEARCH_LENGTH) {
      debounced(() => triggerSearch({ variables: { name: input } }));
    }
  }, [inputValue]);

  const handleOperatorSelect = (option: SelectOption) => {
    const operator: Operator = {
      uuid: option.value,
      name: option.label,
    };

    helper.setValue([...meta.value, operator]);
  };

  const handleOperatorDelete = (operator: Operator) => {
    const reducedOperators = meta.value.filter((value) => value !== operator);
    helper.setValue(reducedOperators);
  };

  if (error) {
    return (
      <DefaultInfoAlert
        message={formatMessage({
          id: "partnerForm.interconnectionRules.operator.load.error",
        })}
        translate={legacyTranslate}
      />
    );
  }

  return (
    <>
      <div className={css.autocompleteWrapper}>
        <Autocomplete
          label={formatMessage({
            id: "partnerForm.interconnectionRules.operator.add.label",
          })}
          placeholder={formatMessage({
            id: "partnerForm.interconnectionRules.operator.add.placeholder",
          })}
          options={options}
          onInputChange={(value) => {
            if (value.length >= MIN_SEARCH_LENGTH) {
              setSearchLoading(true);
            }

            setInputValue(value);
          }}
          startsOnInputValueLength={3}
          onValueSelected={(value) => handleOperatorSelect(value)}
          optionsLoading={searchLoading}
          noOptionsInfo={<div className={css.noOptionInfo}>No operator found</div>}
          clearsOnSelect
          error={!!(meta.error && meta.touched)}
          errorMessage={meta.error}
          onFocus={() => {
            if (meta.error) {
              helper.setError(undefined);
            }
          }}
        />
      </div>

      {operatorsSelected.length > 0 && (
        <div>
          <div data-id="selected-operators-label" className={css.labelWrapper}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="selected-operators-list" className={css.label}>
              <FormattedMessage
                id="partnerForm.interconnectionRules.listOperator.label"
                values={{ numberOfOperators: operatorsSelected.length }}
              />
            </label>
          </div>
          <ListWrapper id="selected-operators-list" small extraClasses={css.listWrapper}>
            {operatorsSelected.map((operator) => (
              <ListWrapperItem key={operator.uuid} Elem="div">
                <div className={css.listWrapperItemContent}>
                  <InterconnectionOperatorIcon isWhitelist={isWhitelist} />
                  <div className={css.listWrapperItemText}>{operator.name}</div>
                  <Icon
                    InlineIcon={IconDelete}
                    size={4}
                    onClick={() => handleOperatorDelete(operator)}
                    extraClasses={css.iconDelete}
                  />
                </div>
              </ListWrapperItem>
            ))}
          </ListWrapper>
        </div>
      )}
    </>
  );
};

export default OperatorSelect;
