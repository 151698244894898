import { startOfMonth, endOfMonth, startOfYear, endOfYear, interval } from "date-fns";
import growInterval from "../buildCalendar/buildCalendar";
import { Calendar, CalendarIntervalType } from "../types";

const calculateCalendarBoundary = (calendar: Calendar, expanded: number[]) => {
  let boundary = { start: new Date(), end: new Date() };

  if (calendar.rows.length) {
    boundary = calendar.rows[0].chunks[0].uiPeriod;
  }

  calendar.rows.forEach((row, rowIndex) => {
    row.chunks.forEach((chunk) => {
      boundary = growInterval(boundary, chunk.uiPeriod);
    });
    if (row.hasInvisibleConceptChanges && expanded.includes(rowIndex)) {
      row.invisibleConceptChanges.forEach((cc) => {
        boundary = growInterval(boundary, cc.uiPeriod);
      });
    }
  });

  return calendar.intervalType === CalendarIntervalType.DAILY
    ? interval(startOfMonth(boundary.start), endOfMonth(boundary.end))
    : interval(startOfYear(boundary.start), endOfYear(boundary.end));
};

export default calculateCalendarBoundary;
