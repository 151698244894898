import {
  autocompleteStyles,
  ClearIndicator,
  DropdownIndicator,
  Option,
  removeSpacesAndSpecialCharacters,
  SingleValue,
  SelectOption,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import ReactSelect from "react-select";
import DropupIndicator from "../../helpers/reactSelect/dropupIndicator/DropupIndicator";
import * as css from "./Select.scss";

export type SelectProps = {
  label?: string;
  placeholder?: string;
  initialValue?: SelectOption;
  options: Array<SelectOption>;
  onSelect: (selectedValue: SelectOption) => void;
  onClear?: () => void;
  onBlur?: () => void;
  errorText?: string;
  isDisabled?: boolean;
  isDropUp?: boolean;
  infoText?: String;
};

const Select: React.FC<SelectProps> = ({
  label,
  placeholder,
  initialValue,
  options,
  onSelect,
  onClear,
  onBlur,
  errorText,
  isDisabled,
  isDropUp = false,
  infoText,
}) => {
  const [value, setValue] = React.useState<SelectOption | undefined>(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <>
      {label && (
        <label className={css.inputLabel} htmlFor={`select-${label}`}>
          {label}
        </label>
      )}
      <ReactSelect
        id={`select-${removeSpacesAndSpecialCharacters(label) || Math.random()}`}
        data-id="select"
        placeholder={placeholder}
        styles={autocompleteStyles(
          !!errorText,
          true,
          isDropUp,
          onClear !== undefined,
          !!label
        )}
        value={value}
        options={options}
        components={{
          ClearIndicator,
          DropdownIndicator: isDropUp ? DropupIndicator : DropdownIndicator,
          Option,
          SingleValue,
        }}
        onChange={(selectedValue) => {
          setValue(selectedValue as SelectOption);
          if (selectedValue) {
            onSelect(selectedValue as SelectOption);
          } else if (onClear) {
            onClear();
          }
        }}
        onBlur={onBlur}
        isDisabled={isDisabled}
        menuPlacement={isDropUp ? "top" : "bottom"}
        isClearable={onClear !== undefined}
        isSearchable={false}
      />
      {infoText && (
        <span data-id="info-message" className={css.info}>
          {infoText}
        </span>
      )}
      {errorText && (
        <span data-id="error-message" className={`${css.info} ${css.error}`}>
          {errorText}
        </span>
      )}
    </>
  );
};

export default Select;
