// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
};

export enum AccessPackage {
  Pro = "PRO",
  Light = "LIGHT",
  View = "VIEW",
}

export type AccountInput = {
  name: Scalars["String"]["input"];
  businessRegionId: Scalars["ID"]["input"];
  concessionOwnerId?: InputMaybe<Scalars["String"]["input"]>;
  businessDevelopers: Array<BusinessDeveloperInput>;
  accessPackage: AccessPackage;
  meansOfTransport?: InputMaybe<MeansOfTransport>;
  currency: Currency;
};

export type Amenity = {
  __typename?: "Amenity";
  amenityType: AmenityType;
  highlighted: Scalars["Boolean"]["output"];
};

export type AmenityInput = {
  amenityType: AmenityType;
  highlighted: Scalars["Boolean"]["input"];
};

export enum AmenityType {
  AirCondition = "AIR_CONDITION",
  Bikes = "BIKES",
  OnboardEntertainment = "ONBOARD_ENTERTAINMENT",
  Pets = "PETS",
  PowerSockets = "POWER_SOCKETS",
  SnacksDrinks = "SNACKS_DRINKS",
  Toilet = "TOILET",
  Usb = "USB",
  Wifi = "WIFI",
  Disinfected = "DISINFECTED",
  Stewards = "STEWARDS",
}

export type ApiDisabledFeatures = {
  __typename?: "ApiDisabledFeatures";
  manageCapacity: Scalars["Boolean"]["output"];
};

export type ApiDisabledFeaturesInput = {
  manageCapacity: Scalars["Boolean"]["input"];
};

export type ApiSettings = {
  __typename?: "ApiSettings";
  apiPartner: Scalars["Boolean"]["output"];
  apiDisabledFeatures: ApiDisabledFeatures;
  ticketSendout: TicketSendout;
};

export type ApiSettingsInput = {
  apiPartner: Scalars["Boolean"]["input"];
  apiDisabledFeatures: ApiDisabledFeaturesInput;
  ticketSendout: TicketSendoutInput;
};

export type AutoOnSaleConfig = {
  __typename?: "AutoOnSaleConfig";
  normalSchedule: Scalars["Boolean"]["output"];
  conceptChangeSchedule: Scalars["Boolean"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
};

export type AutoOnSaleConfigFilterInput = {
  normalSchedule?: InputMaybe<Scalars["Boolean"]["input"]>;
  conceptChangeSchedule?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AutoOnSaleConfigInput = {
  normalSchedule: Scalars["Boolean"]["input"];
  conceptChangeSchedule: Scalars["Boolean"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
};

export type BookableLuggagePolicy = {
  __typename?: "BookableLuggagePolicy";
  bookable: Scalars["Boolean"]["output"];
  luggageSpecification?: Maybe<BookableLuggageSpecification>;
};

export type BookableLuggagePolicyInput = {
  bookable: Scalars["Boolean"]["input"];
  luggageSpecification?: InputMaybe<BookableLuggageSpecificationInput>;
};

export type BookableLuggageSpecification = {
  __typename?: "BookableLuggageSpecification";
  maxWeight?: Maybe<WeightEntry>;
  maxDimension?: Maybe<DimensionEntry>;
  feeType?: Maybe<LuggageFeeType>;
  feePrice?: Maybe<MoneyEntry>;
  pointOfSale?: Maybe<PointOfSaleType>;
  bookableUntil?: Maybe<TimeEntry>;
};

export type BookableLuggageSpecificationInput = {
  maxWeight?: InputMaybe<WeightEntryInput>;
  maxDimension?: InputMaybe<DimensionEntryInput>;
  feeType?: InputMaybe<LuggageFeeType>;
  feePrice?: InputMaybe<MoneyEntryInput>;
  pointOfSale?: InputMaybe<PointOfSaleType>;
  bookableUntil?: InputMaybe<TimeEntryInput>;
};

export type BookingInformationConfiguration = {
  __typename?: "BookingInformationConfiguration";
  passengerInformation: Array<PassengerInformationField>;
  tripInformation: Array<TripInformationField>;
  bookingInformation: Array<BookingInformationField>;
};

export type BookingInformationConfigurationInput = {
  passengerInformation: Array<PassengerInformationField>;
  tripInformation: Array<TripInformationField>;
  bookingInformation: Array<BookingInformationField>;
};

export enum BookingInformationField {
  FlixOrderId = "FLIX_ORDER_ID",
  PartnerOrderId = "PARTNER_ORDER_ID",
  BookingDate = "BOOKING_DATE",
  PaidPrice = "PAID_PRICE",
  BoardingPass = "BOARDING_PASS",
  Invoice = "INVOICE",
}

export type Brand = {
  __typename?: "Brand";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  created?: Maybe<Scalars["String"]["output"]>;
  updated?: Maybe<Scalars["String"]["output"]>;
  policies: Array<Policy>;
  policyUnits: PolicyUnits;
  canBeRemovedFromPartner: Scalars["Boolean"]["output"];
};

export type BrandsInput = {
  brands: Array<Scalars["ID"]["input"]>;
};

export type BusinessDeveloper = {
  __typename?: "BusinessDeveloper";
  name: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  partner: Partner;
};

export type BusinessDeveloperInput = {
  name: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
};

export type BusinessRegion = {
  __typename?: "BusinessRegion";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  networkId: Scalars["String"]["output"];
  countryCode?: Maybe<Scalars["String"]["output"]>;
};

export type CapacityAndAmenitiesInput = {
  minSeatCapacity?: InputMaybe<Scalars["Int"]["input"]>;
  amenities: Array<AmenityInput>;
  defaultCirculationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CapacityAndReportsInput = {
  dataPrivacyContractSigned: Scalars["Boolean"]["input"];
  bookingInformationConfiguration: BookingInformationConfigurationInput;
  capacityDecreaseTimeFrameInHours?: InputMaybe<Scalars["Int"]["input"]>;
  contactEmails: Array<Scalars["String"]["input"]>;
  defaultCirculationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CheckInDesk = {
  __typename?: "CheckInDesk";
  enabled: Scalars["Boolean"]["output"];
  currency?: Maybe<Currency>;
  fee: MoneyEntry;
  feeUnit?: Maybe<CheckInDeskFeeUnit>;
};

export enum CheckInDeskFeeUnit {
  PerBooking = "PER_BOOKING",
  PerPerson = "PER_PERSON",
}

export type CheckInDeskInput = {
  enabled: Scalars["Boolean"]["input"];
  currency?: InputMaybe<Currency>;
  fee: MoneyEntryInput;
  feeUnit?: InputMaybe<CheckInDeskFeeUnit>;
};

export enum CheckInInformation {
  Pdf = "PDF",
  BookingNumber = "BOOKING_NUMBER",
  SecurityCode = "SECURITY_CODE",
  QrCode = "QR_CODE",
}

export type CheckInOptions = {
  __typename?: "CheckInOptions";
  website?: Maybe<Scalars["String"]["output"]>;
  shop: Scalars["Boolean"]["output"];
  checkInDesk: CheckInDesk;
  selfServiceMachine: Scalars["Boolean"]["output"];
};

export type CheckInOptionsInput = {
  website?: InputMaybe<Scalars["String"]["input"]>;
  shop?: InputMaybe<Scalars["Boolean"]["input"]>;
  checkInDesk: CheckInDeskInput;
  selfServiceMachine?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CheckInRequirements = {
  __typename?: "CheckInRequirements";
  checkInTimeStart?: Maybe<TimeEntry>;
  checkInTimeEnd?: Maybe<TimeEntry>;
  checkInOptions: CheckInOptions;
  proofOfIdentityRequired: Array<ProofOfIdentity>;
};

export type CheckInRequirementsInput = {
  checkInTimeStart?: InputMaybe<TimeEntryInput>;
  checkInTimeEnd?: InputMaybe<TimeEntryInput>;
  checkInOptions?: InputMaybe<CheckInOptionsInput>;
  proofOfIdentityRequired: Array<ProofOfIdentity>;
};

export type Circulation = {
  __typename?: "Circulation";
  id: Scalars["ID"]["output"];
  uuid: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  activeConfiguration?: Maybe<CirculationConfiguration>;
};

export type CirculationConfiguration = {
  __typename?: "CirculationConfiguration";
  seatCapacity: Scalars["Int"]["output"];
  bikeCapacity: Scalars["Int"]["output"];
  wheelchairCapacity: Scalars["Int"]["output"];
};

export type City = {
  __typename?: "City";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  countryCode?: Maybe<Scalars["String"]["output"]>;
  networkId?: Maybe<Scalars["String"]["output"]>;
  importanceClass?: Maybe<ImportanceClass>;
  isPublished: Scalars["Boolean"]["output"];
  stations: Array<Station>;
  readOnly: Scalars["Boolean"]["output"];
};

export enum CooperationType {
  S = "S",
  M = "M",
}

export type Country = {
  __typename?: "Country";
  id: Scalars["ID"]["output"];
  code: IsoCountry;
  approver: Array<Scalars["String"]["output"]>;
};

export enum Currency {
  All = "ALL",
  Bam = "BAM",
  Bgn = "BGN",
  Brl = "BRL",
  Cad = "CAD",
  Chf = "CHF",
  Czk = "CZK",
  Dkk = "DKK",
  Eur = "EUR",
  Gbp = "GBP",
  Hrk = "HRK",
  Huf = "HUF",
  Inr = "INR",
  Mkd = "MKD",
  Mxn = "MXN",
  Nok = "NOK",
  Pln = "PLN",
  Ron = "RON",
  Rsd = "RSD",
  Rub = "RUB",
  Sek = "SEK",
  Try = "TRY",
  Uah = "UAH",
  Usd = "USD",
}

export type CustomerServiceAndPoliciesInput = {
  checkInRequirements: CheckInRequirementsInput;
  customerServiceContact: Array<CustomerServiceContactInput>;
  termsAndConditionsUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type CustomerServiceContact = {
  __typename?: "CustomerServiceContact";
  purposeOfContact?: Maybe<PurposeOfContactField>;
  hotlineNumber?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  languagesSpoken: Array<IsoLanguage>;
  workingHours: Array<WorkingHours>;
  holidayWorkingHours: Array<HolidayWorkingHours>;
  timeZoneId?: Maybe<Scalars["String"]["output"]>;
  partner: Partner;
};

export type CustomerServiceContactInput = {
  purposeOfContact?: InputMaybe<PurposeOfContactField>;
  hotlineNumber?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  languagesSpoken?: InputMaybe<Array<IsoLanguage>>;
  timeZoneId?: InputMaybe<Scalars["String"]["input"]>;
  workingHours?: InputMaybe<Array<WorkingHoursInput>>;
  holidayWorkingHours?: InputMaybe<Array<HolidayWorkingHoursInput>>;
};

export type CutOffSales = {
  __typename?: "CutOffSales";
  cutOffSalesDurationInMinutes: Scalars["Int"]["output"];
  cutOffSalesRule: CutOffSalesRule;
};

export type CutOffSalesInput = {
  cutOffSalesDurationInMinutes: Scalars["Int"]["input"];
  cutOffSalesRule: CutOffSalesRule;
};

export enum CutOffSalesRule {
  BeforeDeparture = "BEFORE_DEPARTURE",
  BeforeDayOfDeparture = "BEFORE_DAY_OF_DEPARTURE",
  None = "NONE",
}

export enum DayOfWeek {
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
}

export type DimensionEntry = {
  __typename?: "DimensionEntry";
  value: Scalars["String"]["output"];
};

export type DimensionEntryInput = {
  value: Scalars["String"]["input"];
};

export enum DisabilitySupportRegistrationType {
  NoRegistrationNeeded = "NO_REGISTRATION_NEEDED",
  ContactCustomerService = "CONTACT_CUSTOMER_SERVICE",
}

export enum DisabilitySupportType {
  FoldingWheelchairAllowed = "FOLDING_WHEELCHAIR_ALLOWED",
  ElectricWheelchairAllowed = "ELECTRIC_WHEELCHAIR_ALLOWED",
  ServiceDogAllowed = "SERVICE_DOG_ALLOWED",
}

export enum DisplayedScheduleStatus {
  CreatedViaApi = "CREATED_VIA_API",
  WorkInProgress = "WORK_IN_PROGRESS",
  InReview = "IN_REVIEW",
  Approved = "APPROVED",
  InGeneration = "IN_GENERATION",
  Published = "PUBLISHED",
  Accepted = "ACCEPTED",
  PricesPublished = "PRICES_PUBLISHED",
  Hidden = "HIDDEN",
  OnSale = "ON_SALE",
  PartiallyOnSale = "PARTIALLY_ON_SALE",
  Archived = "ARCHIVED",
  OutOfSale = "OUT_OF_SALE",
}

export type ForbiddenRelation = {
  __typename?: "ForbiddenRelation";
  from: Station;
  to: Station;
};

export type ForbiddenRelationInput = {
  fromId: Scalars["ID"]["input"];
  toId: Scalars["ID"]["input"];
};

export type GeneralSettingsInput = {
  customNetworkCode?: InputMaybe<Scalars["String"]["input"]>;
  networkDescription: Scalars["String"]["input"];
  cooperationType: CooperationType;
  cutOffSales: CutOffSalesInput;
  brandId: Scalars["ID"]["input"];
  meansOfTransport: MeansOfTransport;
};

export type HolidayWorkingHours = {
  __typename?: "HolidayWorkingHours";
  from?: Maybe<Scalars["String"]["output"]>;
  to?: Maybe<Scalars["String"]["output"]>;
};

export type HolidayWorkingHoursInput = {
  from?: InputMaybe<Scalars["String"]["input"]>;
  to?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * An enumeration of ISO 3166-1 alpha-2 country codes from countries which can be
 * supported in context of Flix Marketplace.
 */
export enum IsoCountry {
  /** Andorra */
  Ad = "AD",
  /** Albania */
  Al = "AL",
  /** Austria */
  At = "AT",
  /** Bosnia & Herzegovina */
  Ba = "BA",
  /** Belgium */
  Be = "BE",
  /** Bulgaria */
  Bg = "BG",
  /** Brazil */
  Br = "BR",
  /** Belarus */
  By = "BY",
  /** Canada */
  Ca = "CA",
  /** Switzerland */
  Ch = "CH",
  /** Chile */
  Cl = "CL",
  /** Czechia */
  Cz = "CZ",
  /** Germany */
  De = "DE",
  /** Denmark */
  Dk = "DK",
  /** Estonia */
  Ee = "EE",
  /** Spain */
  Es = "ES",
  /** Finland */
  Fi = "FI",
  /** France */
  Fr = "FR",
  /** Great Britain */
  Gb = "GB",
  /** Greece */
  Gr = "GR",
  /** Croatia */
  Hr = "HR",
  /** Hungary */
  Hu = "HU",
  /** Ireland */
  Ie = "IE",
  /** India */
  In = "IN",
  /** Italy */
  It = "IT",
  /** Liechtenstein */
  Li = "LI",
  /** Lithuania */
  Lt = "LT",
  /** Luxembourg */
  Lu = "LU",
  /** Latvia */
  Lv = "LV",
  /** Morocco */
  Ma = "MA",
  /** Moldova */
  Md = "MD",
  /** Montenegro */
  Me = "ME",
  /** Macedonia */
  Mk = "MK",
  /** Malta */
  Mt = "MT",
  /** Mexico */
  Mx = "MX",
  /** Netherlands */
  Nl = "NL",
  /** Norway */
  No = "NO",
  /** Poland */
  Pl = "PL",
  /** Portugal */
  Pt = "PT",
  /** Romania */
  Ro = "RO",
  /** Serbia */
  Rs = "RS",
  /** Russia */
  Ru = "RU",
  /** Slovenia */
  Si = "SI",
  /** Slovakia */
  Sk = "SK",
  /** Sweden */
  Se = "SE",
  /** San Marino */
  Sm = "SM",
  /** Türkiye */
  Tr = "TR",
  /** Ukraine */
  Ua = "UA",
  /** United States */
  Us = "US",
  /** Kosovo */
  Xk = "XK",
}

/**
 * An enumeration of ISO 639 two-letter language codes from languages which can be
 * supported in context of Flix Marketplace.
 */
export enum IsoLanguage {
  /** Azerbaijani */
  Az = "AZ",
  /** Belarusian */
  Be = "BE",
  /** Bulgarian */
  Bg = "BG",
  /** Bosnian */
  Bs = "BS",
  /** Czech */
  Cs = "CS",
  /** Danish */
  Da = "DA",
  /** German */
  De = "DE",
  /** Greek */
  El = "EL",
  /** English */
  En = "EN",
  /** Spanish */
  Es = "ES",
  /** Estonian */
  Et = "ET",
  /** Finnish */
  Fi = "FI",
  /** French */
  Fr = "FR",
  /** Irish */
  Ga = "GA",
  /** Croatian */
  Hr = "HR",
  /** Hungarian */
  Hu = "HU",
  /** Armenian */
  Hy = "HY",
  /** Icelandic */
  Is = "IS",
  /** Italian */
  It = "IT",
  /** Georgian */
  Ka = "KA",
  /** Kazakh */
  Kk = "KK",
  /** Luxembourgish */
  Lb = "LB",
  /** Lithuanian */
  Lt = "LT",
  /** Latvian */
  Lv = "LV",
  /** Macedonian */
  Mk = "MK",
  /** Maltese */
  Mt = "MT",
  /** Dutch */
  Nl = "NL",
  /** Norwegian */
  No = "NO",
  /** Polish */
  Pl = "PL",
  /** Portuguese */
  Pt = "PT",
  /** Romanian */
  Ro = "RO",
  /** Russian */
  Ru = "RU",
  /** Northern Sami */
  Se = "SE",
  /** Slovak */
  Sk = "SK",
  /** Slovene */
  Sl = "SL",
  /** Albanian */
  Sq = "SQ",
  /** Serbian */
  Sr = "SR",
  /** Swedish */
  Sv = "SV",
  /** Turkish */
  Tr = "TR",
  /** Ukrainian */
  Uk = "UK",
}

export enum ImportanceClass {
  A = "A",
  B = "B",
  C = "C",
}

export type IncludedLuggagePolicy = {
  __typename?: "IncludedLuggagePolicy";
  inTicketIncluded: Scalars["Boolean"]["output"];
  luggageSpecification?: Maybe<IncludedLuggageSpecification>;
};

export type IncludedLuggagePolicyInput = {
  inTicketIncluded: Scalars["Boolean"]["input"];
  luggageSpecification?: InputMaybe<IncludedLuggageSpecificationInput>;
};

export type IncludedLuggageSpecification = {
  __typename?: "IncludedLuggageSpecification";
  maxWeight?: Maybe<WeightEntry>;
  maxDimension?: Maybe<DimensionEntry>;
  maxPieces?: Maybe<Scalars["Int"]["output"]>;
};

export type IncludedLuggageSpecificationInput = {
  maxWeight?: InputMaybe<WeightEntryInput>;
  maxDimension?: InputMaybe<DimensionEntryInput>;
  maxPieces?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InterconnectionRule = {
  __typename?: "InterconnectionRule";
  type: InterconnectionRuleType;
  operators: Array<Operator>;
};

export type InterconnectionRuleInput = {
  type: InterconnectionRuleType;
  operators?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export enum InterconnectionRuleType {
  Whitelist = "WHITELIST",
  Blacklist = "BLACKLIST",
  None = "NONE",
}

export enum InventoryRestriction {
  StandaloneOnly = "STANDALONE_ONLY",
  InterconnectionOnly = "INTERCONNECTION_ONLY",
  None = "NONE",
}

export type InventoryRuleInput = {
  inventoryRestriction: InventoryRestriction;
  interconnectionRule?: InputMaybe<InterconnectionRuleInput>;
};

export type LegalCompany = {
  __typename?: "LegalCompany";
  id: Scalars["String"]["output"];
  contractPartnerId: Scalars["String"]["output"];
  shortCode: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  concessionOwnerId?: Maybe<Scalars["String"]["output"]>;
};

export enum LengthUnit {
  Centimeters = "CENTIMETERS",
  Inches = "INCHES",
}

export type Line = {
  __typename?: "Line";
  id: Scalars["ID"]["output"];
  networkCode: Scalars["String"]["output"];
  networkDescription: Scalars["String"]["output"];
  networkId?: Maybe<Scalars["String"]["output"]>;
  minSeatCapacity: Scalars["Int"]["output"];
  partner: Partner;
  cutOffSales: CutOffSales;
  schedules: Array<Schedule>;
  forbiddenRelations: Array<ForbiddenRelation>;
  deletable: Scalars["Boolean"]["output"];
  isPublished: Scalars["Boolean"]["output"];
  status: LineStatus;
  readOnly: Scalars["Boolean"]["output"];
  /** Deprecated use "isPublished" instead of readOnly */
  cooperationType: CooperationType;
  amenities: Array<Amenity>;
  brand?: Maybe<Brand>;
  defaultCirculation?: Maybe<Circulation>;
  meansOfTransport: MeansOfTransport;
};

export type LineFilterInput = {
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  businessRegions?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  meansOfTransport?: InputMaybe<Array<MeansOfTransport>>;
};

export enum LineStatus {
  Draft = "DRAFT",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum LuggageFeeType {
  PricePerPiece = "PRICE_PER_PIECE",
  PricePerWeight = "PRICE_PER_WEIGHT",
}

export type LuggagePolicy = {
  __typename?: "LuggagePolicy";
  handLuggagePolicy?: Maybe<IncludedLuggagePolicy>;
  checkInLuggagePolicy?: Maybe<IncludedLuggagePolicy>;
  additionalLuggagePolicy?: Maybe<BookableLuggagePolicy>;
  specialLuggagePolicy?: Maybe<BookableLuggagePolicy>;
  pushchairPolicy?: Maybe<BookableLuggagePolicy>;
  bikePolicy?: Maybe<BookableLuggagePolicy>;
  surfboardPolicy?: Maybe<BookableLuggagePolicy>;
  skiEquipmentPolicy?: Maybe<BookableLuggagePolicy>;
  petPolicy?: Maybe<BookableLuggagePolicy>;
};

export type LuggagePolicyInput = {
  handLuggagePolicy?: InputMaybe<IncludedLuggagePolicyInput>;
  checkInLuggagePolicy?: InputMaybe<IncludedLuggagePolicyInput>;
  additionalLuggagePolicy?: InputMaybe<BookableLuggagePolicyInput>;
  specialLuggagePolicy?: InputMaybe<BookableLuggagePolicyInput>;
  pushchairPolicy?: InputMaybe<BookableLuggagePolicyInput>;
  bikePolicy?: InputMaybe<BookableLuggagePolicyInput>;
  surfboardPolicy?: InputMaybe<BookableLuggagePolicyInput>;
  skiEquipmentPolicy?: InputMaybe<BookableLuggagePolicyInput>;
  petPolicy?: InputMaybe<BookableLuggagePolicyInput>;
};

export enum MeansOfTransport {
  Bus = "BUS",
  Train = "TRAIN",
  Ferry = "FERRY",
  MiniBus = "MINI_BUS",
}

export type MobilityPartner = {
  __typename?: "MobilityPartner";
  uuid: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  inventoryRestriction: InventoryRestriction;
  interconnectionRule?: Maybe<InterconnectionRule>;
};

export type MoneyEntry = {
  __typename?: "MoneyEntry";
  value: Scalars["Float"]["output"];
};

export type MoneyEntryInput = {
  value: Scalars["Float"]["input"];
};

export type Mutation = {
  __typename?: "Mutation";
  updateBrand: Brand;
  newCity: City;
  updateCountry: Country;
  newLine: Line;
  updateLine: Line;
  deleteLine: Scalars["Boolean"]["output"];
  updateMobilityPartner?: Maybe<MobilityPartner>;
  newPartner: Partner;
  updatePartner: Partner;
  deletePartner: Scalars["Boolean"]["output"];
  updatePricingPeriods: Schedule;
  publishPrices: Schedule;
  newSchedule: Schedule;
  updateSchedule: Schedule;
  deleteSchedule: Scalars["Boolean"]["output"];
  addStationToSchedule?: Maybe<Schedule>;
  removeStationFromSchedule?: Maybe<Schedule>;
  exchangeStation?: Maybe<Schedule>;
  copySchedule: Schedule;
  createConceptChange: Schedule;
  updateScheduleStatus: Schedule;
  publishSchedule: Schedule;
  changeStatus: Schedule;
  activateOnSale: Scalars["Boolean"]["output"];
  archiveSchedule: Scalars["Boolean"]["output"];
  rejectStation: Station;
  approveStation: Station;
  newStation: Station;
  updateStation: Station;
  updateTripStationDepartureTime?: Maybe<Trip>;
  updateTripStationArrivalTime?: Maybe<Trip>;
  updateTripStationArrivalOffsetDays?: Maybe<Trip>;
  deleteTripStationTime?: Maybe<Trip>;
  newTrip: Trip;
  updateTrip: Trip;
  deleteTrip: Scalars["Boolean"]["output"];
  copyTrip: Trip;
  shiftTrip: Trip;
  updateUserRoles: User;
};

export type MutationUpdateBrandArgs = {
  id: Scalars["ID"]["input"];
  policyUnits: PolicyUnitsInput;
  policies: Array<PolicyInput>;
};

export type MutationNewCityArgs = {
  name: Scalars["String"]["input"];
  importanceClass?: InputMaybe<ImportanceClass>;
  countryCode?: InputMaybe<IsoCountry>;
};

export type MutationUpdateCountryArgs = {
  id: Scalars["ID"]["input"];
  approver: Array<Scalars["String"]["input"]>;
};

export type MutationNewLineArgs = {
  partnerId: Scalars["ID"]["input"];
  generalSettings: GeneralSettingsInput;
  capacityAndAmenities: CapacityAndAmenitiesInput;
  forbiddenRelations: Array<ForbiddenRelationInput>;
};

export type MutationUpdateLineArgs = {
  id: Scalars["ID"]["input"];
  generalSettings?: InputMaybe<GeneralSettingsInput>;
  capacityAndAmenities?: InputMaybe<CapacityAndAmenitiesInput>;
  forbiddenRelations?: InputMaybe<Array<ForbiddenRelationInput>>;
};

export type MutationDeleteLineArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUpdateMobilityPartnerArgs = {
  id: Scalars["ID"]["input"];
  inventoryRule?: InputMaybe<InventoryRuleInput>;
};

export type MutationNewPartnerArgs = {
  accountInput: AccountInput;
  capacityAndReportsInput: CapacityAndReportsInput;
  customerServiceAndPoliciesInput: CustomerServiceAndPoliciesInput;
  brandsInput: BrandsInput;
  partnerSettingsInput: PartnerSettingsInput;
};

export type MutationUpdatePartnerArgs = {
  id: Scalars["ID"]["input"];
  accountInput?: InputMaybe<AccountInput>;
  capacityAndReportsInput?: InputMaybe<CapacityAndReportsInput>;
  customerServiceAndPoliciesInput?: InputMaybe<CustomerServiceAndPoliciesInput>;
  brandsInput?: InputMaybe<BrandsInput>;
  partnerSettingsInput?: InputMaybe<PartnerSettingsInput>;
  partnerTagsInput?: InputMaybe<PartnerTagsInput>;
};

export type MutationDeletePartnerArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUpdatePricingPeriodsArgs = {
  scheduleId: Scalars["ID"]["input"];
  currency: Currency;
  pricingPeriodInputs: Array<PricingPeriodInput>;
};

export type MutationPublishPricesArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationNewScheduleArgs = {
  name: Scalars["String"]["input"];
  period: SchedulePeriodInput;
  lineId: Scalars["ID"]["input"];
};

export type MutationUpdateScheduleArgs = {
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  period?: InputMaybe<SchedulePeriodInput>;
  circulationId?: InputMaybe<Scalars["String"]["input"]>;
  nonOperatingPeriods?: InputMaybe<Array<NonOperatingPeriodInput>>;
};

export type MutationDeleteScheduleArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationAddStationToScheduleArgs = {
  scheduleId: Scalars["ID"]["input"];
  stationId: Scalars["ID"]["input"];
  index?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationRemoveStationFromScheduleArgs = {
  scheduleId: Scalars["ID"]["input"];
  stationId: Scalars["ID"]["input"];
};

export type MutationExchangeStationArgs = {
  scheduleId: Scalars["ID"]["input"];
  originalStationId: Scalars["ID"]["input"];
  exchangeStationId: Scalars["ID"]["input"];
};

export type MutationCopyScheduleArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCreateConceptChangeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUpdateScheduleStatusArgs = {
  id: Scalars["ID"]["input"];
  status: ScheduleStatus;
};

export type MutationPublishScheduleArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationChangeStatusArgs = {
  scheduleId: Scalars["ID"]["input"];
  newStatus: ScheduleStatus;
  comment: Scalars["String"]["input"];
};

export type MutationActivateOnSaleArgs = {
  scheduleId: Scalars["ID"]["input"];
};

export type MutationArchiveScheduleArgs = {
  scheduleId: Scalars["ID"]["input"];
};

export type MutationRejectStationArgs = {
  id: Scalars["ID"]["input"];
  reason: Scalars["String"]["input"];
  alternativeStationId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationApproveStationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationNewStationArgs = {
  name: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
  code?: InputMaybe<Scalars["String"]["input"]>;
  latitude: Scalars["String"]["input"];
  longitude: Scalars["String"]["input"];
  countryCode: IsoCountry;
  zipCode: Scalars["String"]["input"];
  addressCity: Scalars["String"]["input"];
  addressLines: Array<Scalars["String"]["input"]>;
  cityId: Scalars["ID"]["input"];
  transferInformation: StationTransferInformationInput;
};

export type MutationUpdateStationArgs = {
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
  code?: InputMaybe<Scalars["String"]["input"]>;
  shortName?: InputMaybe<Scalars["String"]["input"]>;
  latitude: Scalars["String"]["input"];
  longitude: Scalars["String"]["input"];
  countryCode: IsoCountry;
  zipCode: Scalars["String"]["input"];
  addressCity: Scalars["String"]["input"];
  addressLines: Array<Scalars["String"]["input"]>;
  cityId: Scalars["ID"]["input"];
  transferInformation: StationTransferInformationInput;
};

export type MutationUpdateTripStationDepartureTimeArgs = {
  tripId: Scalars["ID"]["input"];
  stationId: Scalars["ID"]["input"];
  departureTime: Scalars["String"]["input"];
};

export type MutationUpdateTripStationArrivalTimeArgs = {
  tripId: Scalars["ID"]["input"];
  stationId: Scalars["ID"]["input"];
  arrivalTime: Scalars["String"]["input"];
};

export type MutationUpdateTripStationArrivalOffsetDaysArgs = {
  tripId: Scalars["ID"]["input"];
  stationId: Scalars["ID"]["input"];
  arrivalOffsetDays: Scalars["Int"]["input"];
};

export type MutationDeleteTripStationTimeArgs = {
  tripId: Scalars["ID"]["input"];
  stationId: Scalars["ID"]["input"];
};

export type MutationNewTripArgs = {
  direction: TripDirection;
  scheduleId: Scalars["ID"]["input"];
};

export type MutationUpdateTripArgs = {
  id: Scalars["ID"]["input"];
  serviceDays?: InputMaybe<ServiceDaysInput>;
  number?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationDeleteTripArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCopyTripArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationShiftTripArgs = {
  id: Scalars["ID"]["input"];
  departureTime: Scalars["String"]["input"];
};

export type MutationUpdateUserRolesArgs = {
  id: Scalars["ID"]["input"];
  roles: Array<SupportedExternalRole>;
};

export type NonOperatingPeriod = {
  __typename?: "NonOperatingPeriod";
  start: Scalars["Date"]["output"];
  end: Scalars["Date"]["output"];
};

export type NonOperatingPeriodInput = {
  start: Scalars["Date"]["input"];
  end: Scalars["Date"]["input"];
};

export type Operator = {
  __typename?: "Operator";
  uuid: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  totalPages: Scalars["Int"]["output"];
  currentPage: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
  hasPrevious: Scalars["Boolean"]["output"];
};

export type PageRequest = {
  pageSize: PageSize;
  page: Scalars["Int"]["input"];
};

export enum PageSize {
  Twentyfive = "TWENTYFIVE",
  Fifty = "FIFTY",
  Hundred = "HUNDRED",
}

export type PagedCityResult = {
  __typename?: "PagedCityResult";
  total: Scalars["Int"]["output"];
  items: Array<City>;
  pageInfo: PageInfo;
};

export type PagedLineResult = {
  __typename?: "PagedLineResult";
  total: Scalars["Int"]["output"];
  items: Array<Line>;
  pageInfo: PageInfo;
};

export type PagedStationResult = {
  __typename?: "PagedStationResult";
  total: Scalars["Int"]["output"];
  items: Array<Station>;
  pageInfo: PageInfo;
};

export type Partner = {
  __typename?: "Partner";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  businessRegion: BusinessRegion;
  concessionOwnerId?: Maybe<Scalars["String"]["output"]>;
  businessDevelopers: Array<BusinessDeveloper>;
  brands: Array<Brand>;
  dataPrivacyContractSigned: Scalars["Boolean"]["output"];
  bookingInformationConfiguration: BookingInformationConfiguration;
  capacityDecreaseTimeFrameInHours?: Maybe<Scalars["Int"]["output"]>;
  lines: Array<Line>;
  deletable: Scalars["Boolean"]["output"];
  readOnly: Scalars["Boolean"]["output"];
  checkInRequirements?: Maybe<CheckInRequirements>;
  customerServiceContact: Array<CustomerServiceContact>;
  termsAndConditionsUrl?: Maybe<Scalars["String"]["output"]>;
  contactEmails: Array<Scalars["String"]["output"]>;
  defaultCirculation?: Maybe<Circulation>;
  priceChangeAllowed: Scalars["Boolean"]["output"];
  priceDistribution: PriceDistribution;
  externalInterconnectionPricingAllowed: Scalars["Boolean"]["output"];
  apiSettings?: Maybe<ApiSettings>;
  circulations: Array<Circulation>;
  accessPackage: AccessPackage;
  autoOnSaleConfig: AutoOnSaleConfig;
  meansOfTransport?: Maybe<MeansOfTransport>;
  currency: Currency;
  tags: Array<PartnerTag>;
};

export type PartnerFilterInput = {
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  businessRegions?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  apiPartner?: InputMaybe<Scalars["Boolean"]["input"]>;
  meansOfTransport?: InputMaybe<Array<InputMaybe<MeansOfTransport>>>;
  accessPackage?: InputMaybe<Array<AccessPackage>>;
  businessDeveloper?: InputMaybe<Scalars["String"]["input"]>;
  autoOnSaleConfig: AutoOnSaleConfigFilterInput;
  partnerTags?: InputMaybe<Array<PartnerTag>>;
};

export type PartnerSettingsInput = {
  priceChangeAllowed: Scalars["Boolean"]["input"];
  priceDistribution: PriceDistribution;
  externalInterconnectionPricingAllowed: Scalars["Boolean"]["input"];
  apiSettings: ApiSettingsInput;
  autoOnSaleConfig: AutoOnSaleConfigInput;
};

export enum PartnerTag {
  IntegrationTypeSelfService = "INTEGRATION_TYPE_SELF_SERVICE",
  IntegrationTypeFullApi = "INTEGRATION_TYPE_FULL_API",
  IntegrationTypeHybridApi = "INTEGRATION_TYPE_HYBRID_API",
  IntegrationTypeCustomApi = "INTEGRATION_TYPE_CUSTOM_API",
  IntegrationMethodApiDirect = "INTEGRATION_METHOD_API_DIRECT",
  IntegrationMethodApiIntermediary = "INTEGRATION_METHOD_API_INTERMEDIARY",
  BudgetLongDistance = "BUDGET_LONG_DISTANCE",
  BudgetRegional = "BUDGET_REGIONAL",
  BudgetShuttle = "BUDGET_SHUTTLE",
  InventoryYearLongLines = "INVENTORY_YEAR_LONG_LINES",
  InventorySeasonalLines = "INVENTORY_SEASONAL_LINES",
  InventoryMixedLine = "INVENTORY_MIXED_LINE",
  InventoryAirportLines = "INVENTORY_AIRPORT_LINES",
  UsForeignCarriers = "US_FOREIGN_CARRIERS",
  UsTransitCarriers = "US_TRANSIT_CARRIERS",
  IntegrationStatusInIntegration = "INTEGRATION_STATUS_IN_INTEGRATION",
  IntegrationStatusInTesting = "INTEGRATION_STATUS_IN_TESTING",
  IntegrationStatusNewCarrier = "INTEGRATION_STATUS_NEW_CARRIER",
  WarningToMonitor = "WARNING_TO_MONITOR",
  WarningDontOpenSales = "WARNING_DONT_OPEN_SALES",
  WarningTestAccount = "WARNING_TEST_ACCOUNT",
  WarningCancelledContract = "WARNING_CANCELLED_CONTRACT",
  WarningUnderReview = "WARNING_UNDER_REVIEW",
  Dt = "DT",
  SalesApi = "SALES_API",
  Inactive = "INACTIVE",
  NoCommissionRateSettlement = "NO_COMMISSION_RATE_SETTLEMENT",
  FranchiseAccount = "FRANCHISE_ACCOUNT",
}

export type PartnerTagsInput = {
  partnerTags: Array<PartnerTag>;
};

export enum PassengerInformationField {
  Name = "NAME",
  Phone = "PHONE",
  EMail = "E_MAIL",
  Nationality = "NATIONALITY",
  Gender = "GENDER",
  TicketType = "TICKET_TYPE",
  Birthday = "BIRTHDAY",
  IdentityDocument = "IDENTITY_DOCUMENT",
  IdentityDocumentNumber = "IDENTITY_DOCUMENT_NUMBER",
  SeatLabels = "SEAT_LABELS",
  Luggage = "LUGGAGE",
  Wheelchair = "WHEELCHAIR",
  CheckInStatus = "CHECK_IN_STATUS",
}

export type PassengerPolicy = {
  __typename?: "PassengerPolicy";
  unaccompaniedMinorPolicy?: Maybe<UnaccompaniedMinorPolicy>;
  passengerWithDisabilityPolicy?: Maybe<PassengerWithDisabilityPolicy>;
};

export type PassengerPolicyInput = {
  unaccompaniedMinorPolicy?: InputMaybe<UnaccompaniedMinorPolicyInput>;
  passengerWithDisabilityPolicy?: InputMaybe<PassengerWithDisabilityPolicyInput>;
};

export type PassengerWithDisabilityPolicy = {
  __typename?: "PassengerWithDisabilityPolicy";
  supported: Scalars["Boolean"]["output"];
  passengerWithDisabilitySpecification?: Maybe<PassengerWithDisabilitySpecification>;
};

export type PassengerWithDisabilityPolicyInput = {
  supported: Scalars["Boolean"]["input"];
  passengerWithDisabilitySpecification?: InputMaybe<PassengerWithDisabilitySpecificationInput>;
};

export type PassengerWithDisabilitySpecification = {
  __typename?: "PassengerWithDisabilitySpecification";
  proofOfDisabilityRequired?: Maybe<Scalars["Boolean"]["output"]>;
  registrationInstruction?: Maybe<DisabilitySupportRegistrationType>;
  registrationUntil?: Maybe<TimeEntry>;
  supportingServices: Array<DisabilitySupportType>;
};

export type PassengerWithDisabilitySpecificationInput = {
  proofOfDisabilityRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  registrationInstruction?: InputMaybe<DisabilitySupportRegistrationType>;
  registrationUntil?: InputMaybe<TimeEntryInput>;
  supportingServices: Array<DisabilitySupportType>;
};

export type Period = {
  __typename?: "Period";
  start: Scalars["Date"]["output"];
  end: Scalars["Date"]["output"];
};

export type PeriodInput = {
  start: Scalars["Date"]["input"];
  end: Scalars["Date"]["input"];
};

export enum PointOfSaleType {
  CustomerService = "CUSTOMER_SERVICE",
  OnBoard = "ON_BOARD",
}

export type Policy = {
  __typename?: "Policy";
  brand: Brand;
  type: PolicyType;
  luggagePolicy?: Maybe<LuggagePolicy>;
  passengerPolicy?: Maybe<PassengerPolicy>;
  additionalInformation?: Maybe<Scalars["String"]["output"]>;
};

export type PolicyInput = {
  type: PolicyType;
  luggagePolicy: LuggagePolicyInput;
  passengerPolicy: PassengerPolicyInput;
  additionalInformation?: InputMaybe<Scalars["String"]["input"]>;
};

export enum PolicyType {
  Both = "BOTH",
  International = "INTERNATIONAL",
  Domestic = "DOMESTIC",
}

export type PolicyUnits = {
  __typename?: "PolicyUnits";
  weightUnit: WeightUnit;
  lengthUnit: LengthUnit;
  currency: Currency;
};

export type PolicyUnitsInput = {
  weightUnit: WeightUnit;
  lengthUnit: LengthUnit;
  currency: Currency;
};

export enum PriceDirection {
  UniDirectional = "UNI_DIRECTIONAL",
  BiDirectional = "BI_DIRECTIONAL",
}

export enum PriceDistribution {
  PerLeg = "PER_LEG",
  PerDistance = "PER_DISTANCE",
}

export type PricingPeriod = {
  __typename?: "PricingPeriod";
  id: Scalars["ID"]["output"];
  direction: PriceDirection;
  period?: Maybe<Period>;
  relationMatrix: RelationMatrix;
};

export type PricingPeriodInput = {
  direction: PriceDirection;
  period?: InputMaybe<PeriodInput>;
  relationMatrix: RelationMatrixInput;
};

export enum ProofOfIdentity {
  Passport = "PASSPORT",
  Id = "ID",
  MinorsNeedProof = "MINORS_NEED_PROOF",
}

export enum PurposeOfContactField {
  All = "ALL",
  Refund = "REFUND",
  Feedback = "FEEDBACK",
  Luggage = "LUGGAGE",
  PassengersWithDisabilities = "PASSENGERS_WITH_DISABILITIES",
  ChildrenReservation = "CHILDREN_RESERVATION",
  DelayInformation = "DELAY_INFORMATION",
}

export type Query = {
  __typename?: "Query";
  findAllAvailableBrands: Array<Brand>;
  findBrand: Brand;
  findAllBusinessRegions: Array<BusinessRegion>;
  filterCities: PagedCityResult;
  findAllCities: Array<City>;
  findCity: City;
  findAllCountries: Array<Country>;
  findAllLegalCompanies: Array<LegalCompany>;
  filterLines: PagedLineResult;
  findLine: Line;
  findLineByCode?: Maybe<Line>;
  searchOperators: Array<Operator>;
  findMobilityPartner?: Maybe<MobilityPartner>;
  findPartner: Partner;
  filterPartners: Array<Partner>;
  findSchedule: Schedule;
  validateSchedule: ValidationResult;
  findOriginalSchedules: Array<Schedule>;
  filterStations: PagedStationResult;
  findStation: Station;
  searchStations: Array<Station>;
  findNearbyStations: Array<Station>;
  findUsers: Array<User>;
};

export type QueryFindBrandArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryFilterCitiesArgs = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<IsoCountry>;
  importanceClass?: InputMaybe<ImportanceClass>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  pageRequest: PageRequest;
};

export type QueryFindCityArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryFilterLinesArgs = {
  filter: LineFilterInput;
  pageRequest: PageRequest;
};

export type QueryFindLineArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryFindLineByCodeArgs = {
  networkCode: Scalars["String"]["input"];
};

export type QuerySearchOperatorsArgs = {
  name: Scalars["String"]["input"];
};

export type QueryFindMobilityPartnerArgs = {
  partnerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryFindPartnerArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryFilterPartnersArgs = {
  partnerFilterInput: PartnerFilterInput;
};

export type QueryFindScheduleArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryValidateScheduleArgs = {
  scheduleId: Scalars["ID"]["input"];
};

export type QueryFindOriginalSchedulesArgs = {
  lineId: Scalars["ID"]["input"];
  excludeInactive?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryFilterStationsArgs = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["ID"]["input"]>;
  country?: InputMaybe<IsoCountry>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<StationStatus>;
  pageRequest: PageRequest;
};

export type QueryFindStationArgs = {
  id: Scalars["ID"]["input"];
};

export type QuerySearchStationsArgs = {
  searchterm: Scalars["String"]["input"];
};

export type QueryFindNearbyStationsArgs = {
  latitude: Scalars["String"]["input"];
  longitude: Scalars["String"]["input"];
};

export type QueryFindUsersArgs = {
  partnerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type RelationMatrix = {
  __typename?: "RelationMatrix";
  rows: Array<RelationMatrixRow>;
};

export type RelationMatrixEntry = {
  __typename?: "RelationMatrixEntry";
  from: Station;
  to: Station;
  type: RelationType;
  price?: Maybe<Scalars["Float"]["output"]>;
};

export type RelationMatrixEntryInput = {
  fromId: Scalars["ID"]["input"];
  toId: Scalars["ID"]["input"];
  type: RelationType;
  price?: InputMaybe<Scalars["Float"]["input"]>;
};

export type RelationMatrixInput = {
  rows: Array<RelationMatrixRowInput>;
};

export type RelationMatrixRow = {
  __typename?: "RelationMatrixRow";
  entries: Array<RelationMatrixEntry>;
};

export type RelationMatrixRowInput = {
  entries: Array<RelationMatrixEntryInput>;
};

export enum RelationType {
  Regular = "REGULAR",
  Forbidden = "FORBIDDEN",
  Unserved = "UNSERVED",
}

export type Schedule = {
  __typename?: "Schedule";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  period: SchedulePeriod;
  status: ScheduleStatus;
  line: Line;
  stations: Array<Station>;
  trips: Array<Trip>;
  statusHistory: Array<ScheduleStatusHistoryEntry>;
  nonOperatingPeriods: Array<NonOperatingPeriod>;
  circulation?: Maybe<Circulation>;
  deletable: Scalars["Boolean"]["output"];
  readOnly: Scalars["Boolean"]["output"];
  lastPublishFailed: Scalars["Boolean"]["output"];
  currency: Currency;
  conceptChangeOf?: Maybe<Schedule>;
  conceptChanges: Array<Schedule>;
  originalSchedule: Schedule;
  hasPrices: Scalars["Boolean"]["output"];
  hasValidPrices: Scalars["Boolean"]["output"];
  hasPublishedPrices: Scalars["Boolean"]["output"];
  isOutdated: Scalars["Boolean"]["output"];
  canBeUsedForPriceImport: Scalars["Boolean"]["output"];
  publishedDate?: Maybe<Scalars["String"]["output"]>;
  scheduleDirection: ScheduleDirection;
  wasCreatedViaApi: Scalars["Boolean"]["output"];
  displayedScheduleStatus: DisplayedScheduleStatus;
  onSalePeriods: Array<Period>;
  pricingPeriods: Array<PricingPeriod>;
};

export enum ScheduleDirection {
  OneDirectional = "ONE_DIRECTIONAL",
  TwoDirectional = "TWO_DIRECTIONAL",
}

export type SchedulePeriod = {
  __typename?: "SchedulePeriod";
  start: Scalars["Date"]["output"];
  end: Scalars["Date"]["output"];
};

export type SchedulePeriodInput = {
  start: Scalars["Date"]["input"];
  end: Scalars["Date"]["input"];
};

export enum ScheduleStatus {
  CreatedViaApi = "CREATED_VIA_API",
  WorkInProgress = "WORK_IN_PROGRESS",
  InReview = "IN_REVIEW",
  Approved = "APPROVED",
  InGeneration = "IN_GENERATION",
  Published = "PUBLISHED",
  Accepted = "ACCEPTED",
  PricesPublished = "PRICES_PUBLISHED",
  OnSale = "ON_SALE",
  OutOfSale = "OUT_OF_SALE",
}

export type ScheduleStatusHistoryEntry = {
  __typename?: "ScheduleStatusHistoryEntry";
  id: Scalars["ID"]["output"];
  status: ScheduleStatus;
  schedule: Schedule;
  description?: Maybe<Scalars["String"]["output"]>;
  created: Scalars["String"]["output"];
  createdBy: Scalars["String"]["output"];
};

export type ServiceDays = {
  __typename?: "ServiceDays";
  monday: Scalars["Boolean"]["output"];
  tuesday: Scalars["Boolean"]["output"];
  wednesday: Scalars["Boolean"]["output"];
  thursday: Scalars["Boolean"]["output"];
  friday: Scalars["Boolean"]["output"];
  saturday: Scalars["Boolean"]["output"];
  sunday: Scalars["Boolean"]["output"];
};

export type ServiceDaysInput = {
  monday: Scalars["Boolean"]["input"];
  tuesday: Scalars["Boolean"]["input"];
  wednesday: Scalars["Boolean"]["input"];
  thursday: Scalars["Boolean"]["input"];
  friday: Scalars["Boolean"]["input"];
  saturday: Scalars["Boolean"]["input"];
  sunday: Scalars["Boolean"]["input"];
};

export type Station = {
  __typename?: "Station";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  code?: Maybe<Scalars["String"]["output"]>;
  shortName?: Maybe<Scalars["String"]["output"]>;
  timeZone: StationTimeZone;
  latitude?: Maybe<Scalars["String"]["output"]>;
  longitude?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  addressLines: Array<Scalars["String"]["output"]>;
  zipCode?: Maybe<Scalars["String"]["output"]>;
  addressCity?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<City>;
  isPublished: Scalars["Boolean"]["output"];
  transferInformation?: Maybe<StationTransferInformation>;
  updatedDate?: Maybe<Scalars["String"]["output"]>;
  status: StationStatus;
  rejectionReason?: Maybe<Scalars["String"]["output"]>;
  alternativeStation?: Maybe<Station>;
  hasNearbyStation?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum StationStatus {
  InReview = "IN_REVIEW",
  Approved = "APPROVED",
  Published = "PUBLISHED",
  Rejected = "REJECTED",
}

export type StationTime = {
  __typename?: "StationTime";
  station: Station;
  arrivalTime?: Maybe<Scalars["String"]["output"]>;
  arrivalOffsetWeekdays: Scalars["Int"]["output"];
  departureTime?: Maybe<Scalars["String"]["output"]>;
  departureOffsetWeekdays: Scalars["Int"]["output"];
  timeToStation?: Maybe<Scalars["String"]["output"]>;
  estimatedTimeToStation?: Maybe<Scalars["String"]["output"]>;
  arrivalOffsetDays: Scalars["Int"]["output"];
};

export type StationTimeZone = {
  __typename?: "StationTimeZone";
  zoneId: Scalars["String"]["output"];
  utcOffset: Scalars["String"]["output"];
  rawUtcOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  observesDaylightSavingTime: Scalars["Boolean"]["output"];
};

export type StationTransferInformation = {
  __typename?: "StationTransferInformation";
  isTransferStation: Scalars["Boolean"]["output"];
  transferOpeningHours: Array<StationTransferInformationOpeningHours>;
  minimumTransferMinutes?: Maybe<Scalars["Int"]["output"]>;
};

export type StationTransferInformationInput = {
  isTransferStation: Scalars["Boolean"]["input"];
  transferOpeningHours?: InputMaybe<Array<StationTransferInformationOpeningHoursInput>>;
  minimumTransferMinutes?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StationTransferInformationOpeningHours = {
  __typename?: "StationTransferInformationOpeningHours";
  weekday: DayOfWeek;
  start: Scalars["String"]["output"];
  end: Scalars["String"]["output"];
};

export type StationTransferInformationOpeningHoursInput = {
  weekday: DayOfWeek;
  start: Scalars["String"]["input"];
  end: Scalars["String"]["input"];
};

export enum SupportedExternalRole {
  RoleCapacityManager = "ROLE_CAPACITY_MANAGER",
  RoleGeneralSettings = "ROLE_GENERAL_SETTINGS",
  RolePricing = "ROLE_PRICING",
  RoleReporting = "ROLE_REPORTING",
  RoleScheduleManager = "ROLE_SCHEDULE_MANAGER",
  RoleUserManager = "ROLE_USER_MANAGER",
  RoleDispatcher = "ROLE_DISPATCHER",
  RoleDriver = "ROLE_DRIVER",
  RolePowerBi = "ROLE_POWER_BI",
}

export type TicketSendout = {
  __typename?: "TicketSendout";
  enabled: Scalars["Boolean"]["output"];
  checkInInformation: Array<CheckInInformation>;
};

export type TicketSendoutInput = {
  enabled: Scalars["Boolean"]["input"];
  checkInInformation: Array<CheckInInformation>;
};

export type TimeEntry = {
  __typename?: "TimeEntry";
  value: Scalars["Int"]["output"];
  unit: TimeUnit;
};

export type TimeEntryInput = {
  value: Scalars["Int"]["input"];
  unit: TimeUnit;
};

export enum TimeUnit {
  Minutes = "MINUTES",
  Hours = "HOURS",
  Days = "DAYS",
}

export type Trip = {
  __typename?: "Trip";
  id: Scalars["ID"]["output"];
  number: Scalars["Int"]["output"];
  direction: TripDirection;
  serviceDays: ServiceDays;
  schedule: Schedule;
  stationTimes: Array<StationTime>;
  deletable: Scalars["Boolean"]["output"];
  readOnly: Scalars["Boolean"]["output"];
  totalTripDuration: Scalars["String"]["output"];
  estimatedTripDuration?: Maybe<Scalars["String"]["output"]>;
};

export enum TripDirection {
  Outward = "OUTWARD",
  Return = "RETURN",
}

export enum TripInformationField {
  LineCode = "LINE_CODE",
  TravelDate = "TRAVEL_DATE",
  DepartureTime = "DEPARTURE_TIME",
  ArrivalTime = "ARRIVAL_TIME",
  DepartureAndArrivalStation = "DEPARTURE_AND_ARRIVAL_STATION",
  InterconnectionTrip = "INTERCONNECTION_TRIP",
}

export type UnaccompaniedMinorAllowed = {
  __typename?: "UnaccompaniedMinorAllowed";
  parentalConsentRequired?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UnaccompaniedMinorAllowedInput = {
  parentalConsentRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UnaccompaniedMinorPolicy = {
  __typename?: "UnaccompaniedMinorPolicy";
  allowed: Scalars["Boolean"]["output"];
  unaccompaniedMinorSpecification?: Maybe<UnaccompaniedMinorSpecification>;
};

export type UnaccompaniedMinorPolicyInput = {
  allowed: Scalars["Boolean"]["input"];
  unaccompaniedMinorSpecification?: InputMaybe<UnaccompaniedMinorSpecificationInput>;
};

export type UnaccompaniedMinorSpecification = {
  __typename?: "UnaccompaniedMinorSpecification";
  minAgeYears?: Maybe<Scalars["Int"]["output"]>;
  internationalTravelAllowed: Scalars["Boolean"]["output"];
  nightTravelAllowed: Scalars["Boolean"]["output"];
  interconnectionTravelAllowed: Scalars["Boolean"]["output"];
  internationalTravel?: Maybe<UnaccompaniedMinorAllowed>;
  nightTravel?: Maybe<UnaccompaniedMinorAllowed>;
  interconnectionTravel?: Maybe<UnaccompaniedMinorAllowed>;
};

export type UnaccompaniedMinorSpecificationInput = {
  minAgeYears?: InputMaybe<Scalars["Int"]["input"]>;
  internationalTravelAllowed: Scalars["Boolean"]["input"];
  nightTravelAllowed: Scalars["Boolean"]["input"];
  interconnectionTravelAllowed: Scalars["Boolean"]["input"];
  internationalTravel?: InputMaybe<UnaccompaniedMinorAllowedInput>;
  nightTravel?: InputMaybe<UnaccompaniedMinorAllowedInput>;
  interconnectionTravel?: InputMaybe<UnaccompaniedMinorAllowedInput>;
};

export type User = {
  __typename?: "User";
  id: Scalars["String"]["output"];
  mail: Scalars["String"]["output"];
  displayName: Scalars["String"]["output"];
  roles: Array<SupportedExternalRole>;
};

export type ValidationInfo = {
  __typename?: "ValidationInfo";
  key: ValidationKey;
  valid: Scalars["Boolean"]["output"];
  infoText: Scalars["String"]["output"];
  failedValidationInfos: Array<Scalars["String"]["output"]>;
};

export enum ValidationKey {
  Partner = "PARTNER",
  City = "CITY",
  Cities = "CITIES",
  Line = "LINE",
  Schedule = "SCHEDULE",
  ScheduleCapacity = "SCHEDULE_CAPACITY",
  ScheduleNonOperatingDays = "SCHEDULE_NON_OPERATING_DAYS",
  ScheduleTripsInSequence = "SCHEDULE_TRIPS_IN_SEQUENCE",
  Station = "STATION",
  Stations = "STATIONS",
  TripNumberRange = "TRIP_NUMBER_RANGE",
  TripNumberDuplicates = "TRIP_NUMBER_DUPLICATES",
  TripServiceDays = "TRIP_SERVICE_DAYS",
  TripServiceDaysNotOperated = "TRIP_SERVICE_DAYS_NOT_OPERATED",
  TripSegmentExistence = "TRIP_SEGMENT_EXISTENCE",
  TripSegmentInvalid = "TRIP_SEGMENT_INVALID",
  TripContainsHook = "TRIP_CONTAINS_HOOK",
  TripDepartureStation = "TRIP_DEPARTURE_STATION",
  TripOnlyOneCity = "TRIP_ONLY_ONE_CITY",
  Prices = "PRICES",
}

export type ValidationResult = {
  __typename?: "ValidationResult";
  validationInfos: Array<ValidationInfo>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
};

export type WeightEntry = {
  __typename?: "WeightEntry";
  value: Scalars["Int"]["output"];
};

export type WeightEntryInput = {
  value: Scalars["Int"]["input"];
};

export enum WeightUnit {
  Kilograms = "KILOGRAMS",
  Pounds = "POUNDS",
}

export type WorkingHours = {
  __typename?: "WorkingHours";
  workingDays: Array<DayOfWeek>;
  from?: Maybe<Scalars["String"]["output"]>;
  to?: Maybe<Scalars["String"]["output"]>;
};

export type WorkingHoursInput = {
  workingDays?: InputMaybe<Array<DayOfWeek>>;
  from?: InputMaybe<Scalars["String"]["input"]>;
  to?: InputMaybe<Scalars["String"]["input"]>;
};
