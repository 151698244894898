import isDateWithinPeriod from "../isDateWithinPeriod/isDateWithinPeriod";
import { Calendar, ScheduleChunk } from "../types";

const findChunk = (
  calendar: Calendar,
  date: Date,
  row: number
): ScheduleChunk | undefined =>
  calendar.rows[row].chunks.find((chunk) =>
    isDateWithinPeriod(calendar.intervalType, date, chunk.uiPeriod)
  );

export default findChunk;
