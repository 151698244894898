import { Icon, IconInfo } from "@flixbus/honeycomb-icons-react";
import { Label, Tooltip, TooltipProps } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./TooltipLabel.scss";

export type FormikInputProps = {
  id: string;
  labelText: string;
  content: JSX.Element;
  required?: boolean;
  size?: TooltipProps["size"];
};

const TooltipLabel: React.FC<FormikInputProps> = ({
  id,
  labelText,
  content,
  required,
  size,
}) => {
  return (
    <Label
      labelFor={id}
      extraClasses={css.label}
      text={
        <Tooltip id={`tooltip-${id}`} content={content} openOnHover size={size}>
          <span>
            {labelText}
            &nbsp;
            <Icon InlineIcon={IconInfo} size={3} extraClasses={css.icon} />
            {`${required ? " *" : ""}`}
          </span>
        </Tooltip>
      }
    />
  );
};

export default TooltipLabel;
