import { Skeleton } from "@flixbus/honeycomb-react";
import { Autocomplete, SelectOption } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import SelectApiError from "../../../../shared/components/selectApiError/SelectApiError";
import sortOptionsByLabel from "../../../../shared/helpers/autocompleteOptions/sortOptionsByLabel";
import { useFindAllCitiesForFilterQuery } from "../../api/operations.generated";
import defaultOption from "../../helper/defaultOption/defaultOption";
import * as css from "./FilterCitySelect.scss";

type Props = {
  selected: SelectOption["value"] | null;
  onChange: (value: SelectOption["value"] | null) => void;
};

const FilterCitySelect: React.FC<Props> = ({ selected, onChange }) => {
  const { formatMessage } = useIntl();
  const {
    loading,
    data: allCitiesData,
    error,
  } = useFindAllCitiesForFilterQuery({
    fetchPolicy: "cache-first",
  });

  if (loading) {
    return (
      <>
        <Skeleton extraClasses={css.labelSkeleton} height="sm" width="sm" />
        <Skeleton height="lg" flushBottom />
      </>
    );
  }

  if (allCitiesData?.findAllCities) {
    const options = sortOptionsByLabel(allCitiesData.findAllCities);

    const initialOption = options.find((option) => selected === option.value);

    return (
      <Autocomplete
        label={formatMessage({ id: "general.city" })}
        initialValue={initialOption || defaultOption()}
        isClearable={initialOption !== undefined}
        options={options}
        onValueSelected={(selection) => {
          if (selection === null || selection.value === "") {
            onChange(null);
          } else {
            onChange(selection.value);
          }
        }}
        startsOnInputValueLength={3}
      />
    );
  }

  if (error && !loading) {
    return (
      <SelectApiError
        label={formatMessage({ id: "general.city" })}
        info={formatMessage({ id: "error.errorOccured" })}
      />
    );
  }

  return <></>;
};

export default FilterCitySelect;
