import { Autocomplete, SelectOption } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import { createCountryOptions } from "../../../../shared/helpers/autocompleteOptions/createOptions";
import sortOptionsByLabel from "../../../../shared/helpers/autocompleteOptions/sortOptionsByLabel";
import { IsoCountry } from "../../../../shared/types/schema";
import defaultOption from "../../helper/defaultOption/defaultOption";

type Props = {
  selected: SelectOption["value"] | null;
  onChange: (value: IsoCountry | null) => void;
};

const FilterCountrySelect: React.FC<Props> = ({ selected, onChange }) => {
  const { formatMessage } = useIntl();
  const options = sortOptionsByLabel(createCountryOptions());
  const initialOption = options.find((option) => selected === option.value);

  return (
    <Autocomplete
      label={formatMessage({ id: "general.country" })}
      initialValue={initialOption || defaultOption()}
      options={options}
      isClearable={initialOption !== undefined}
      onValueSelected={(selection) => {
        if (selection === null || selection.value === "") {
          onChange(null);
        } else {
          onChange(selection.value as IsoCountry);
        }
      }}
      startsOnInputValueLength={0}
    />
  );
};

export default FilterCountrySelect;
