import { useReactiveVar } from "@apollo/client";
import { VirtualItem } from "@tanstack/react-virtual";
import * as React from "react";
import expandedCalendarRowsVar from "../../../../state/expandedCalendarRows/expandedCalendarRowsVar";
import findChunk from "../../helper/findChunk/findChunk";
import {
  calculateRowHeight,
  contentCellHeight,
  headerHeight,
} from "../../helper/sizes/sizes";
import { Calendar } from "../../helper/types";
import CalendarContentCell from "../calendarContentCell/CalendarContentCell";
import CalendarExpandButtonRow from "../calendarExpandButtonRow/CalendarExpandButtonRow";
import InvisibleConceptChangeRow from "../invisibleConceptChangeRow/InvisibleConceptChangeRow";

export type CalendarContentProps = {
  calendar: Calendar;
  rows: VirtualItem[];
  columns: VirtualItem[];
  displayDates: Date[];
};

const CalendarContent: React.FC<CalendarContentProps> = ({
  calendar,
  rows,
  columns,
  displayDates,
}) => {
  const [hoveredSchedule, setHoveredSchedule] = React.useState<string>();
  const expandedRows = useReactiveVar(expandedCalendarRowsVar);

  const toggleRowExpansion = (rowIndex: number, expand: boolean) => {
    if (expand) {
      expandedCalendarRowsVar([...expandedRows, rowIndex]);
    } else {
      expandedCalendarRowsVar(expandedRows.filter((i) => i !== rowIndex));
    }
  };

  return (
    <>
      {rows.map((virtualRowItem) => {
        const rowPosition = virtualRowItem.start + headerHeight;
        const rowIndex = virtualRowItem.index;
        const calendarRow = calendar.rows[rowIndex];
        const { hasInvisibleConceptChanges } = calendarRow;
        const expanded = expandedRows?.includes(rowIndex) ?? false;

        return (
          <React.Fragment key={`${virtualRowItem.key}`}>
            <div
              data-testing="calendar-row"
              style={{
                transform: `translateY(${rowPosition}px)`,
              }}
            >
              {columns.map((virtualColItem) => {
                const chunk = findChunk(
                  calendar,
                  displayDates[virtualColItem.index],
                  rowIndex
                );

                return (
                  <CalendarContentCell
                    key={`${virtualColItem.key}`}
                    intervalType={calendar.intervalType}
                    currentDate={displayDates[virtualColItem.index]}
                    virtualColItem={virtualColItem}
                    hoveredSchedule={hoveredSchedule}
                    setHoveredSchedule={setHoveredSchedule}
                    schedules={chunk?.schedules ?? []}
                    isFirstOccurrence={chunk?.isFirstOccurrence || false}
                    period={chunk?.uiPeriod}
                    hasInvisibleConceptChanges={hasInvisibleConceptChanges}
                  />
                );
              })}
            </div>

            {hasInvisibleConceptChanges && (
              <>
                {expanded &&
                  calendarRow.invisibleConceptChanges.map((cc, ccIndex) => (
                    <InvisibleConceptChangeRow
                      key={cc.schedule.id}
                      conceptChange={cc}
                      rowStart={
                        rowPosition + contentCellHeight + ccIndex * contentCellHeight
                      }
                      columns={columns}
                      displayDates={displayDates}
                      intervalType={calendar.intervalType}
                    />
                  ))}
                <CalendarExpandButtonRow
                  rowStart={rowPosition + calculateRowHeight(calendarRow, expanded)}
                  expanded={expanded}
                  onClick={() => {
                    toggleRowExpansion(rowIndex, !expanded);
                  }}
                  columns={columns}
                />
              </>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default CalendarContent;
