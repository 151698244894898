import { Infobox } from "@flixbus/honeycomb-react";
import { ContactUsLink, legacyTranslate } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./PriceInfobox.scss";

type Props = {
  isError: boolean;
};

const PriceInfobox: React.FC<Props> = ({ isError }) => {
  return (
    <div className={css.wrapper}>
      <Infobox appearance={isError ? "danger" : "warning"}>
        {isError ? (
          <p>
            <FormattedMessage id="general.somethingWentWrong" />
            &nbsp;
            <ContactUsLink
              translate={legacyTranslate}
            />.
          </p>
        ) : (
          <p>
            <FormattedMessage id="prices.infoBox.timeTableNeeded" />
          </p>
        )}
      </Infobox>
    </div>
  );
};

export default PriceInfobox;
