@use "variables" as *;

.inputLabel {
  color: $content-secondary-color;
}

.icon {
  padding-right: $spacing-2;
  display: flex;
}

.info {
  position: relative;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  display: block;
  padding-top: 6px;
  color: $content-primary-color;
}

.error {
  color: $danger-dark-color;
}
