import { useReactiveVar } from "@apollo/client";
import {
  Icon,
  IconArrowBigTopRight,
  IconArrowDown,
  IconArrowUp,
} from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import { VirtualItem } from "@tanstack/react-virtual";
import React from "react";
import { FormattedMessage } from "react-intl";
import showInactiveSchedulesVar from "../../../../state/showInactiveSchedulesVar/showInactiveSchedulesVar";
import { expandButtonRowHeight } from "../../helper/sizes/sizes";
import * as css from "./CalendarExpandButtonRow.scss";

export type CalendarExpandButtonRowProps = {
  rowStart: number;
  expanded: boolean;
  onClick: () => void;
  columns: VirtualItem[];
};

const CalendarExpandButtonRow: React.FC<CalendarExpandButtonRowProps> = ({
  rowStart,
  expanded,
  onClick,
  columns,
}) => {
  const showInactive = useReactiveVar(showInactiveSchedulesVar);

  return (
    <div
      className={css.expandButtonRow}
      style={{
        height: `${expandButtonRowHeight}px`,
        top: `${rowStart - expandButtonRowHeight}px`,
      }}
    >
      <Button extraClasses={css.button} size="sm" onClick={onClick}>
        <Icon InlineIcon={IconArrowBigTopRight} />
        {expanded ? (
          <FormattedMessage
            id={
              showInactive
                ? "scheduleCalendar.schedule.expanded.includingInactive"
                : "scheduleCalendar.schedule.expanded"
            }
          />
        ) : (
          <FormattedMessage
            id={
              showInactive
                ? "scheduleCalendar.schedule.collapsed.includingInactive"
                : "scheduleCalendar.schedule.collapsed"
            }
          />
        )}
        <Icon InlineIcon={expanded ? IconArrowUp : IconArrowDown} />
      </Button>

      {columns.map((virtualColItem) => (
        <div
          className={css.col}
          key={`${virtualColItem.key}`}
          style={{
            width: `${virtualColItem.size}px`,
            transform: `translateX(${virtualColItem.start}px)`,
          }}
        />
      ))}
    </div>
  );
};

export default CalendarExpandButtonRow;
