import {
  IsoCountry,
  Station,
  StationTimeZone,
  StationTransferInformationOpeningHours,
} from "../../shared/types/schema";

export type StationFormDefaultValues = Pick<
  Station,
  | "name"
  | "shortName"
  | "code"
  | "latitude"
  | "longitude"
  | "countryCode"
  | "addressLines"
  | "zipCode"
  | "addressCity"
  | "transferInformation"
> & { city?: Pick<NonNullable<Station["city"]>, "id"> } & {
  timeZone: Pick<StationTimeZone, "zoneId" | "observesDaylightSavingTime">;
};

export type StationFormValues = {
  name: string;
  timezone: string;
  shortName: string;
  code: string;
  latitude: string;
  longitude: string;
  countryCode: IsoCountry;
  cityId: string;
  addressLines: Array<string>;
  zipCode: string;
  addressCity: string;
  transferInformation: {
    isTransferStation: boolean;
    transferTime: TransferTime;
    minimumTransferMinutes: number;
    transferOpeningHours: Array<Array<StationTransferInformationOpeningHours>>;
  };
};

export enum TransferTime {
  DYNAMIC = "DYNAMIC",
  FIXED = "FIXED",
}
