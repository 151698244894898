import { formatMessage, SelectOption } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { IsoCountry, Maybe } from "../../types/schema";
import getCountryFlag from "../getCountryFlag/getCountryFlag";
import sortOptionsByLabel from "./sortOptionsByLabel";

export const createTranslatedOptionsFromEnum = (
  optionsEnum: any,
  translationKeyPrefix: string
): Array<SelectOption> => {
  return (Object.values(optionsEnum) as Array<string>).map((option) => {
    return { value: option, label: formatMessage(`${translationKeyPrefix}.${option}`) };
  });
};

type ExtendedSelectOption = SelectOption & {
  code?: Maybe<string>;
};

export const createOptions = (
  selectOptions: Array<ExtendedSelectOption>,
  sorted: Boolean = true
) => {
  const prefixedSelectOptions: Array<SelectOption> = selectOptions.map(
    ({ value, label, code }) => {
      if (code && code.trim().length > 0) {
        return {
          value,
          label: `${code} - ${label}`,
        };
      }

      return {
        value,
        label,
      };
    }
  );

  return sorted ? sortOptionsByLabel(prefixedSelectOptions) : prefixedSelectOptions;
};

export const createCountryOptions = (): Array<SelectOption> =>
  Object.values(IsoCountry).map((country) => ({
    value: country,
    label: formatMessage(`country.${country}`),
    icon: (
      <img
        src={getCountryFlag(country)}
        alt={formatMessage(`country.${country}`)}
        height="25px"
        width="25px"
      />
    ),
  }));
