import { SelectGroup, TooltipProps } from "@flixbus/honeycomb-react";
import { ReadOnlyFormField } from "@flixbus-phx/marketplace-common";
import { useField } from "formik2";
import * as React from "react";
import TooltipLabel from "../tooltipLabel/TooltipLabel";

type Props = {
  name: string;
  label: string;
  defaultOption: string;
  defaultOptionLabel: string;
  alternativeOption: string;
  alternativeOptionLabel: string;
  readOnly?: boolean;
  info?: string;
  required?: boolean;
  defaultIsPreselected?: boolean;
  tooltipContent?: JSX.Element;
  tooltipSize?: TooltipProps["size"];
};

const FormikSelectGroup: React.FC<Props> = ({
  name,
  label,
  defaultOption,
  defaultOptionLabel,
  alternativeOption,
  alternativeOptionLabel,
  readOnly,
  info,
  required,
  defaultIsPreselected = true,
  tooltipContent,
  tooltipSize,
}) => {
  const [field, meta, helper] = useField(name);

  const handleChange = (value: string) => {
    helper.setValue(value);
    // https://github.com/formium/formik/issues/2083#issuecomment-571259235
    helper.setTouched(true, false);
  };

  const options = [
    {
      id: `${name}-${defaultOption}`,
      value: defaultOption,
      name: `${name}`,
      label: defaultOptionLabel,
      checked:
        (meta.value === undefined && defaultIsPreselected) ||
        meta.value === defaultOption,
      onChange: () => handleChange(defaultOption),
    },
    {
      id: `${name}-${alternativeOption}`,
      value: alternativeOption,
      name: `${name}`,
      label: alternativeOptionLabel,
      checked: meta.value === alternativeOption,
      onChange: () => handleChange(alternativeOption),
    },
  ];

  if (readOnly) {
    return (
      <ReadOnlyFormField label={label}>
        {meta.value === alternativeOption ? alternativeOptionLabel : defaultOptionLabel}
      </ReadOnlyFormField>
    );
  }

  const LABEL = tooltipContent ? (
    <TooltipLabel
      id={name}
      labelText={label}
      content={tooltipContent}
      required={required}
      size={tooltipSize}
    />
  ) : (
    `${label}${required ? " *" : ""}`
  );

  return (
    <SelectGroup
      label={LABEL}
      options={options}
      {...field}
      info={info}
      error={!!(meta.touched && meta.error)}
      infoError={meta.error}
    />
  );
};

export default FormikSelectGroup;
