import { FormikAutocomplete, timezones } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikTimeInput from "../../../../shared/components/formikTimeInput/FormikTimeInput";
import FormikDaySelect from "../formikDaySelect/FormikDaySelect";
import * as css from "./CustomerServiceWorkingHoursRow.scss";

type Props = {
  indexOfContact: number;
  indexOfWorkingHour: number;
  name: string;
  readOnly?: boolean;
};

const CustomerServiceWorkingHoursRow: React.FC<Props> = ({
  indexOfContact,
  indexOfWorkingHour,
  name,
  readOnly,
}) => {
  const { formatMessage } = useIntl();
  return (
    <div
      className={cx(
        css.wrapper,
        indexOfWorkingHour === 0 && css.firstRow,
        indexOfWorkingHour > 0 && css.row
      )}
    >
      <FormikDaySelect
        label={formatMessage({ id: "general.dayOfTheWeek.label" })}
        name={`${name}.workingHours[${indexOfWorkingHour}].workingDays`}
        readOnly={readOnly}
        selectIdentificator={`${indexOfContact}-${indexOfWorkingHour}`}
      />

      <div className={css.timeInputWrapper}>
        <FormikTimeInput
          label={formatMessage({ id: "general.fromTime" })}
          name={`${name}.workingHours[${indexOfWorkingHour}].from`}
          readOnly={readOnly}
        />

        <FormikTimeInput
          label={formatMessage({ id: "general.toTime" })}
          name={`${name}.workingHours[${indexOfWorkingHour}].to`}
          readOnly={readOnly}
        />
      </div>

      {indexOfWorkingHour === 0 && (
        <div>
          <FormikAutocomplete
            label={formatMessage({ id: "timezone.select.label" })}
            placeholder={formatMessage({ id: "timezone.select.placeholder" })}
            options={timezones()}
            name={`${name}.timeZoneId`}
            readOnly={readOnly}
            startsOnInputValueLength={0}
          />
        </div>
      )}
    </div>
  );
};

export default CustomerServiceWorkingHoursRow;
