import { compareDesc, interval } from "date-fns";
import { DisplayedScheduleStatus } from "../../../../shared/types/schema";
import getAggregatedScheduleStatus from "../getAggregatedScheduleStatus/getAggregatedScheduleStatus";
import isOrWasScheduleOnSale from "../isOrWasScheduleOnSale/isOrWasScheduleOnSale";
import {
  AggregatedScheduleStatus,
  CalendarSchedule,
  InputSchedule,
  InvisibleConceptChange,
} from "../types";

const statusOrder = {
  ON_SALE: 0,
  PROCESSING: 1,
  HIDDEN: 2,
  IN_REVIEW: 3,
  DRAFT: 4,
  INACTIVE: 5,
};

export const compareConceptChanges = (
  cc1: InvisibleConceptChange,
  cc2: InvisibleConceptChange
): number => {
  const cc1Status = getAggregatedScheduleStatus(cc1.schedule.displayedScheduleStatus);
  const cc2Status = getAggregatedScheduleStatus(cc2.schedule.displayedScheduleStatus);

  const statusCompare = statusOrder[cc1Status] - statusOrder[cc2Status];

  return statusCompare === 0
    ? compareDesc(cc1.uiPeriod.start, cc2.uiPeriod.start)
    : statusCompare;
};

export const isConceptChangeInvisible = (
  schedule: Pick<InputSchedule, "displayedScheduleStatus">,
  isOriginalScheduleOnSale: boolean,
  withInactive: boolean
): boolean => {
  const inactiveConceptChangeStatuses = [
    AggregatedScheduleStatus.DRAFT,
    AggregatedScheduleStatus.IN_REVIEW,
    AggregatedScheduleStatus.PROCESSING,
    AggregatedScheduleStatus.HIDDEN,
  ];

  if (!isOriginalScheduleOnSale) {
    inactiveConceptChangeStatuses.push(AggregatedScheduleStatus.ON_SALE);
  }

  if (withInactive) {
    inactiveConceptChangeStatuses.push(AggregatedScheduleStatus.INACTIVE);
  }

  const aggregatedScheduleStatus = getAggregatedScheduleStatus(
    schedule.displayedScheduleStatus
  );

  return inactiveConceptChangeStatuses.includes(aggregatedScheduleStatus);
};

export const getInvisibleConceptChanges = (
  originalSchedule: InputSchedule,
  conceptChanges: Array<CalendarSchedule>,
  withInactive: boolean
): Array<InvisibleConceptChange> =>
  conceptChanges
    .filter(
      (cc) =>
        cc.displayedScheduleStatus !== DisplayedScheduleStatus.OutOfSale &&
        isConceptChangeInvisible(
          cc,
          isOrWasScheduleOnSale(originalSchedule),
          withInactive
        )
    )
    .map((cc) => ({
      uiPeriod: interval(cc.period!!.start, cc.period!!.end),
      schedule: cc,
    }))
    .sort(compareConceptChanges);
